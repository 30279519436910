import React from 'react'
import RecentBlog from './RecentBlog'

function FAQ() {
  return (
    <div>
        <div>
        <div style={{ backgroundColor: "#0d1e67", minHeight: 400 }}>
          <div className="container" style={{ maxWidth: 1170 }}>
            {/* Navbar */}
            {/* Breadcrumbs */}
            <div className="pt-5 text-center text-white">
              <h1 className="text-white pt-5">FAQ</h1>
              <div className="d-flex align-items-center justify-content-center pt-2">
                <p>
                  <i className="fa-solid fa-house-chimney" />
                  <span className="ps-1">Home</span>
                </p>
                <p className="px-3">
                  <i className="fa-solid fa-angle-right" />
                </p>
                <p>FAQ</p>
              </div>
            </div>
          </div>
        </div>
        {/* Team */}
        <div className="text-center sections-margin">
          <div className="container" style={{ maxWidth: 1170 }}>
            <p className="badge badge-primary">Faq</p>
            <h1 className="pt-3 pb-2">Frequently Asked Questions</h1>
            {/* Accordian */}
            <div
              className="accordion accordion-flush pt-4"
              id="accordionFlushExample"
            >
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseOne"
                    aria-expanded="false"
                    aria-controls="flush-collapseOne"
                  >
                    How Much Your Product Price?
                  </button>
                </h2>
                <div
                  id="flush-collapseOne"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form, by injected humour, or randomised words which don’t look
                    even slightly believable. If you are going to use a passage of
                    Lorem Ipsum, you need to be sure there isn’t anything.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo"
                    aria-expanded="false"
                    aria-controls="flush-collapseTwo"
                  >
                    Do you improve your product continuously?
                  </button>
                </h2>
                <div
                  id="flush-collapseTwo"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form, by injected humour, or randomised words which don’t look
                    even slightly believable. If you are going to use a passage of
                    Lorem Ipsum, you need to be sure there isn’t anything.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseThree"
                    aria-expanded="false"
                    aria-controls="flush-collapseThree"
                  >
                    How Does Your Product Work?
                  </button>
                </h2>
                <div
                  id="flush-collapseThree"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form, by injected humour, or randomised words which don’t look
                    even slightly believable. If you are going to use a passage of
                    Lorem Ipsum, you need to be sure there isn’t anything.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFour"
                    aria-expanded="false"
                    aria-controls="flush-collapseFour"
                  >
                    This Book Is A Treatise On The Theory?
                  </button>
                </h2>
                <div
                  id="flush-collapseFour"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form, by injected humour, or randomised words which don’t look
                    even slightly believable. If you are going to use a passage of
                    Lorem Ipsum, you need to be sure there isn’t anything.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseFive"
                    aria-expanded="false"
                    aria-controls="flush-collapseFive"
                  >
                    Where Does It Come From?
                  </button>
                </h2>
                <div
                  id="flush-collapseFive"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionFlushExample"
                >
                  <div className="accordion-body">
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered alteration in some
                    form, by injected humour, or randomised words which don’t look
                    even slightly believable. If you are going to use a passage of
                    Lorem Ipsum, you need to be sure there isn’t anything.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Language */}
        <div className="sections-margin text-start bg-img-language py-5">
          <div className="container py-5" style={{ maxWidth: 1170 }}>
            <div className="row text-white">
              <div className="col-lg-6 mb-lg-0 mb-5">
                <p className="badge badge-primary">Language</p>
                <h1 className="py-4 text-white">
                  Work with the language you already love
                </h1>
                <p>
                  It is a long established fact that a reader will be distracted
                  by the readable content of a page when looking at its layout.
                  The point of using Lorem Ipsum is that it has a more-or-less
                  normal distribution.
                </p>
                <div className="row py-4">
                  <div className="col-lg-6">
                    <div
                      className="d-flex align-items-center justify-content-start"
                      style={{ gap: 10 }}
                    >
                      <i
                        className="fa-solid fa-check"
                        style={{ color: "#007BFC" }}
                      />
                      <p> Public Cloud </p>
                    </div>
                    <div
                      className="d-flex align-items-center py-2 justify-content-start"
                      style={{ gap: 10 }}
                    >
                      <i
                        className="fa-solid fa-check"
                        style={{ color: "#007BFC" }}
                      />
                      <p> Private Cloud </p>
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-start"
                      style={{ gap: 10 }}
                    >
                      <i
                        className="fa-solid fa-check"
                        style={{ color: "#007BFC" }}
                      />
                      <p> Virtual Private Cloud </p>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div
                      className="d-flex align-items-center justify-content-start"
                      style={{ gap: 10 }}
                    >
                      <i
                        className="fa-solid fa-check"
                        style={{ color: "#007BFC" }}
                      />
                      <p> Tally-as-a-Service </p>
                    </div>
                    <div
                      className="d-flex align-items-center py-2 justify-content-start"
                      style={{ gap: 10 }}
                    >
                      <i
                        className="fa-solid fa-check"
                        style={{ color: "#007BFC" }}
                      />
                      <p> Banking Community Cloud </p>
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-start"
                      style={{ gap: 10 }}
                    >
                      <i
                        className="fa-solid fa-check"
                        style={{ color: "#007BFC" }}
                      />
                      <p> Load Balancing </p>
                    </div>
                  </div>
                </div>
                <button
                  className="blue-btn mt-2"
                  type="submit"
                  style={{ height: "auto", padding: "9px 30px 10px 30px" }}
                >
                  Read more
                </button>
              </div>
              <div className="col-lg-6">
                {/* Pop up */}
                <div className="d-flex align-items-center justify-content-center h-100">
                  {/* Button trigger modal */}
                  <span className="model-btn model-animation" />
                  <button
                    type="button"
                    className="model-btn"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                  >
                    <i className="fa-solid fa-play" />
                  </button>
                  {/* Modal */}
                  <div
                    className="modal fade"
                    id="exampleModalCenter"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered"
                      role="document"
                    >
                      <div className="modal-content">
                        <iframe
                          width={560}
                          height={315}
                          src="https://www.youtube.com/embed/XHOmBV4js_E?si=RHiQmm-mN4eFOTME"
                          title="YouTube video player"
                          frameBorder={0}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerPolicy="strict-origin-when-cross-origin"
                          allowFullScreen=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* <iframe width="560" height="315"
                        src="https://www.youtube.com/embed/XHOmBV4js_E?si=RHiQmm-mN4eFOTME"
                        title="YouTube video player" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
              </div>
            </div>
          </div>
        </div>
        {/* Blog */}
        <div className="text-center sections-margin">
          <div className="container" style={{ maxWidth: 1170 }}>
            <p className="badge badge-primary">Blog</p>
            <h1 className="pb-4 pt-3">Recent Blog</h1>
            {/* Carousel */}
            <RecentBlog />
            {/* <div className="owl-carousel owl-theme text-start second-carousel">
              <div className="item">
                <div className="card blogs-card">
                  <div className="Img-blog">
                    <img src="/images/04.png" alt="" />
                  </div>
                  <div className="bottom-card px-3 pb-3">
                    <div className="marketing-badge-top">
                      <badge className="marketing-badge">MARKETING</badge>
                    </div>
                    <div
                      className="d-flex align-items-center py-3"
                      style={{ fontSize: 14, color: "#007BFC" }}
                    >
                      <i className="fa-solid fa-calendar-days" />
                      <p className="ps-2">February 17, 2020</p>
                    </div>
                    <h5>Official Terraform Provider</h5>
                    <p className="pb-3 pt-1">
                      It is a long established fact that a reader will be
                      distracted by the readable.
                    </p>
                    <p
                      className="d-flex align-items-center"
                      style={{ color: "#007bfc", gap: 15 }}
                    >
                      {" "}
                      Read More <i className="fa-solid fa-angle-right" />
                    </p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="card blogs-card">
                  <div className="Img-blog">
                    <img src="/images/04.png" alt="" />
                  </div>
                  <div className="bottom-card px-3 pb-3">
                    <div className="marketing-badge-top">
                      <badge className="marketing-badge">MARKETING</badge>
                    </div>
                    <div
                      className="d-flex align-items-center py-3"
                      style={{ fontSize: 14, color: "#007BFC" }}
                    >
                      <i className="fa-solid fa-calendar-days" />
                      <p className="ps-2">February 17, 2020</p>
                    </div>
                    <h5>CCPA Compliance Update</h5>
                    <p className="pb-3 pt-1">
                      It is a long established fact that a reader will be
                      distracted by the readable.
                    </p>
                    <p
                      className="d-flex align-items-center"
                      style={{ color: "#007bfc", gap: 15 }}
                    >
                      {" "}
                      Read More <i className="fa-solid fa-angle-right" />
                    </p>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="card blogs-card">
                  <div className="Img-blog">
                    <img src="/images/04.png" alt="" />
                  </div>
                  <div className="bottom-card px-3 pb-3">
                    <div className="marketing-badge-top">
                      <badge className="marketing-badge">MARKETING</badge>
                    </div>
                    <div
                      className="d-flex align-items-center py-3"
                      style={{ fontSize: 14, color: "#007BFC" }}
                    >
                      <i className="fa-solid fa-calendar-days" />
                      <p className="ps-2">February 17, 2020</p>
                    </div>
                    <h5>Build Construction</h5>
                    <p className="pb-3 pt-1">
                      It is a long established fact that a reader will be
                      distracted by the readable.
                    </p>
                    <p
                      className="d-flex align-items-center"
                      style={{ color: "#007bfc", gap: 15 }}
                    >
                      {" "}
                      Read More <i className="fa-solid fa-angle-right" />
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {/* Footer-portion */}
        <div className="mt-lg-5 mt-4" style={{ backgroundColor: "#0d1e67" }}>
          {/* Subscribe */}
          <div style={{ backgroundColor: "#0b1a58", color: "#fff" }}>
            <div className="container" style={{ maxWidth: 1170 }}>
              <div className="d-flex align-items justify-content-between flex-md-row flex-column py-5">
                <h1 className="mb-0" style={{ color: "#fff" }}>
                  Subscribe for Newsletter
                </h1>
                <div className="input-form mt-md-0 mt-4">
                  <input type="email" placeholder="Your email address" />
                  <button className="blue-btn ">Subscribe</button>
                </div>
              </div>
            </div>
          </div>
          {/* Footer */}
        </div>
      </div>
    </div>
  )
}

export default FAQ