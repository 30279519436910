import React from 'react'
import { Container } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

function Carosal() {

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 2000, min: 1200 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 1199, min: 992 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 991, min: 768 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 767, min: 500 },
          items: 1
        }
      };
    
    

  return (
    <div>

<div className='carousel_home'>
      <Container>
          <Carousel
        responsive={responsive}
           swipeable={true}
           draggable={true}
           showDots={true}
           autoPlaySpeed={3000}
           autoPlay={true}
           infinite={true} 
           loop={true}
          >
            <div>
            <div className="Process-cards p-md-5 p-3">
                      <p>
                        Text of the printing and typesetting industry. Lorem Ipsum
                        has been the industry’s standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and
                        scrambled.
                      </p>
                      <div
                        className="d-flex align-items-center pt-5"
                        style={{ gap: 15 }}
                      >
                        <div className="rounded-image">
                          <img src="/images/03.png" alt="" />
                        </div>
                        <div>
                          <h6
                            className="mb-1"
                            style={{ color: "#007BFC", fontSize: 18 }}
                          >
                            Rinks Cooper
                          </h6>
                          <p style={{ fontSize: 14 }}>Designer , Qluod</p>
                        </div>
                      </div>
                    </div>
                  </div>
            <div>
            <div className="Process-cards p-md-5 p-3">
                      <p>
                        Text of the printing and typesetting industry. Lorem Ipsum
                        has been the industry’s standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and
                        scrambled.
                      </p>
                      <div
                        className="d-flex align-items-center pt-5"
                        style={{ gap: 15 }}
                      >
                        <div className="rounded-image">
                          <img src="/images/03.png" alt="" />
                        </div>
                        <div>
                          <h6
                            className="mb-1"
                            style={{ color: "#007BFC", fontSize: 18 }}
                          >
                            Rinks Cooper
                          </h6>
                          <p style={{ fontSize: 14 }}>Designer , Qluod</p>
                        </div>
                      </div>
                    </div>
            </div>
            <div>
            <div className="Process-cards p-md-5 p-3">
                      <p>
                        Text of the printing and typesetting industry. Lorem Ipsum
                        has been the industry’s standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and
                        scrambled.
                      </p>
                      <div
                        className="d-flex align-items-center pt-5"
                        style={{ gap: 15 }}
                      >
                        <div className="rounded-image">
                          <img src="/images/03.png" alt="" />
                        </div>
                        <div>
                          <h6
                            className="mb-1"
                            style={{ color: "#007BFC", fontSize: 18 }}
                          >
                            Rinks Cooper
                          </h6>
                          <p style={{ fontSize: 14 }}>Designer , Qluod</p>
                        </div>
                      </div>
                    </div>
            </div>
            <div>
            <div className="Process-cards p-md-5 p-3">
                      <p>
                        Text of the printing and typesetting industry. Lorem Ipsum
                        has been the industry’s standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and
                        scrambled.
                      </p>
                      <div
                        className="d-flex align-items-center pt-5"
                        style={{ gap: 15 }}
                      >
                        <div className="rounded-image">
                          <img src="/images/03.png" alt="" />
                        </div>
                        <div>
                          <h6
                            className="mb-1"
                            style={{ color: "#007BFC", fontSize: 18 }}
                          >
                            Rinks Cooper
                          </h6>
                          <p style={{ fontSize: 14 }}>Designer , Qluod</p>
                        </div>
                      </div>
                    </div>
            </div>
            <div>
            <div className="Process-cards p-md-5 p-3">
                      <p>
                        Text of the printing and typesetting industry. Lorem Ipsum
                        has been the industry’s standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and
                        scrambled.
                      </p>
                      <div
                        className="d-flex align-items-center pt-5"
                        style={{ gap: 15 }}
                      >
                        <div className="rounded-image">
                          <img src="/images/03.png" alt="" />
                        </div>
                        <div>
                          <h6
                            className="mb-1"
                            style={{ color: "#007BFC", fontSize: 18 }}
                          >
                            Rinks Cooper
                          </h6>
                          <p style={{ fontSize: 14 }}>Designer , Qluod</p>
                        </div>
                      </div>
                    </div>
            </div>
            <div>
            <div className="Process-cards p-md-5 p-3">
                      <p>
                        Text of the printing and typesetting industry. Lorem Ipsum
                        has been the industry’s standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and
                        scrambled.
                      </p>
                      <div
                        className="d-flex align-items-center pt-5"
                        style={{ gap: 15 }}
                      >
                        <div className="rounded-image">
                          <img src="/images/03.png" alt="" />
                        </div>
                        <div>
                          <h6
                            className="mb-1"
                            style={{ color: "#007BFC", fontSize: 18 }}
                          >
                            Rinks Cooper
                          </h6>
                          <p style={{ fontSize: 14 }}>Designer , Qluod</p>
                        </div>
                      </div>
                    </div>
            </div>
 </Carousel>
      </Container>
    </div>


    </div>
  )
}

export default Carosal