// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* Home.css */

/* Remove padding and margin for the entire container and columns */
/* .no-padding {
    padding: 0 ;
  }
  
  .no-margin {
    margin: 0 ;
  }
   */
   .navbar .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
  }
  
  .navbar .btn-secondary {
    background-color: #6c757d;
    border-color: #6c757d;
  }
  .second-section{
    border-left: 1px solid grey;
  }
  .service-img{
    max-width: 35%;
    height: auto;
  }
  .service-text{
    font-size: 17px;
  }
  .btn-img{
    width: 30px;
    height: 30px;
  }
  .btn-img1{
    width: 30px;
    height: 30px;
  }
  .nav-btn:hover{
    background-color: gray !important;
    color: black !important;
  }
  .nav-btn{
    margin: 3px;
    font-size: 14px;
    color: black;
    border-radius: 0%;
    background-color: #fdfefd !important;
    border-left: 3px solid gray !important;
    border-right: 3px solid gray !important;
    border-top: none;
    border-bottom: none;
    outline: none;
  }
  .nav-container{
    background-color:#fdfefd !important ;
  }
  .second-section{
    background-color: gray !important;
  }`, "",{"version":3,"sources":["webpack://./src/Components/console.css"],"names":[],"mappings":";AACA,aAAa;;AAEb,mEAAmE;AACnE;;;;;;;IAOI;GACD;IACC,yBAAyB;IACzB,qBAAqB;EACvB;;EAEA;IACE,yBAAyB;IACzB,qBAAqB;EACvB;EACA;IACE,2BAA2B;EAC7B;EACA;IACE,cAAc;IACd,YAAY;EACd;EACA;IACE,eAAe;EACjB;EACA;IACE,WAAW;IACX,YAAY;EACd;EACA;IACE,WAAW;IACX,YAAY;EACd;EACA;IACE,iCAAiC;IACjC,uBAAuB;EACzB;EACA;IACE,WAAW;IACX,eAAe;IACf,YAAY;IACZ,iBAAiB;IACjB,oCAAoC;IACpC,sCAAsC;IACtC,uCAAuC;IACvC,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;EACf;EACA;IACE,oCAAoC;EACtC;EACA;IACE,iCAAiC;EACnC","sourcesContent":["\r\n/* Home.css */\r\n\r\n/* Remove padding and margin for the entire container and columns */\r\n/* .no-padding {\r\n    padding: 0 ;\r\n  }\r\n  \r\n  .no-margin {\r\n    margin: 0 ;\r\n  }\r\n   */\r\n   .navbar .btn-primary {\r\n    background-color: #007bff;\r\n    border-color: #007bff;\r\n  }\r\n  \r\n  .navbar .btn-secondary {\r\n    background-color: #6c757d;\r\n    border-color: #6c757d;\r\n  }\r\n  .second-section{\r\n    border-left: 1px solid grey;\r\n  }\r\n  .service-img{\r\n    max-width: 35%;\r\n    height: auto;\r\n  }\r\n  .service-text{\r\n    font-size: 17px;\r\n  }\r\n  .btn-img{\r\n    width: 30px;\r\n    height: 30px;\r\n  }\r\n  .btn-img1{\r\n    width: 30px;\r\n    height: 30px;\r\n  }\r\n  .nav-btn:hover{\r\n    background-color: gray !important;\r\n    color: black !important;\r\n  }\r\n  .nav-btn{\r\n    margin: 3px;\r\n    font-size: 14px;\r\n    color: black;\r\n    border-radius: 0%;\r\n    background-color: #fdfefd !important;\r\n    border-left: 3px solid gray !important;\r\n    border-right: 3px solid gray !important;\r\n    border-top: none;\r\n    border-bottom: none;\r\n    outline: none;\r\n  }\r\n  .nav-container{\r\n    background-color:#fdfefd !important ;\r\n  }\r\n  .second-section{\r\n    background-color: gray !important;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
