import React from 'react'

function TabCards() {
  return (
    <div>
        <div className="tabs-card">
                          <div className="tab-img-box">
                            <img src="/images/10.png" alt="" />
                            <div className="tabs-hover-bg">
                              <div className="tab-hover-icon">
                                <i className="fa-solid fa-link" />
                              </div>
                            </div>
                          </div>
                          <div className="tab-cards-body pt-3">
                            <h5>Dignissimos wisi cillum</h5>
                            <p>Print — Branding</p>
                          </div>
                        </div>
    </div>
  )
}

export default TabCards