import React, { useEffect } from "react";
import Carosal from "./Carosal";
import { useNavigate } from "react-router-dom";
import { supabase } from "../supabaseClient";
const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    supabase.auth.onAuthStateChange((event, session) => {
      console.log("🚀 ~ supabase.auth.onAuthStateChange ~ session:", session);
      if (session) {
        localStorage.setItem(
          "app-access-token",
          JSON.stringify(session.access_token)
        );
        localStorage.setItem(
          "refresh_token",
          JSON.stringify(session.refresh_token)
        );
        localStorage.setItem("user", JSON.stringify(session.user));
        // Redirect user after successful login
        // navigate('/dashboard'); // Change '/dashboard' to your desired route
      }
    });
  }, [navigate]);

  return (
    <div>
      <>
        <div>
          <div className="bg-img">
            <div className="container" style={{ maxWidth: 1170 }}>
              {/* Navbar */}
              <div className="Global-padding">
                <div className="row">
                  <div className="col-lg-6">
                    <h1 className="Storage-service">
                      Global Cloud
                      <br />
                      Service
                    </h1>
                    <button className="blue-btn my-5">
                      Start a Free Trial
                    </button>
                    <div
                      className="text-white d-flex align-items-center"
                      style={{ gap: 20, fontSize: 24 }}
                    >
                      <i className="fa-brands fa-gitlab" />
                      <i className="fa-solid fa-cloud-arrow-up" />
                      <i className="fa-solid fa-recycle" />
                      <i className="fa-brands fa-gitlab" />
                    </div>
                  </div>
                  <div
                    className="col-lg-6 d-lg-block d-none"
                    style={{ position: "relative" }}
                  >
                    <div className="img-cloud">
                      <img src="/images/cloud2.png" alt="" />
                    </div>
                    <div className="img-right">
                      <img src="/images/img-right.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Process */}
          <div className="text-center sections-margin-home">
            <div className="container" style={{ maxWidth: 1170 }}>
              <p className="badge badge-primary">Process</p>
              <h1 className="pb-4 pt-3">How its work</h1>
              <div className="row mt-5">
                <div className="col-lg-4 col-sm-6">
                  <div className="div">
                    <div className="Process-cards hover-cards">
                      <div
                        className="d-flex justify-content-center"
                        style={{ marginTop: "-30px" }}
                      >
                        <img src="/images/02.png" alt="" />
                      </div>
                      <h5 className="py-4">Cloud Compute</h5>
                      <p>
                        {" "}
                        It is a long established fact that a reader will be
                        distracted by the of readable content Deploy at scale on
                        the.
                      </p>
                      <p
                        className="d-flex align-items-center justify-content-center pt-4"
                        style={{ color: "#0D1E67", gap: 15 }}
                      >
                        {" "}
                        Read More <i className="fa-solid fa-angle-right" />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 ">
                  <div className="mt-sm-0 mt-5">
                    <div className="Process-cards hover-cards">
                      <div
                        className="d-flex justify-content-center"
                        style={{ marginTop: "-30px" }}
                      >
                        <img src="/images/02.png" alt="" />
                      </div>
                      <h5 className="py-4">Block Storage</h5>
                      <p>
                        {" "}
                        It is a long established fact that a reader will be
                        distracted by the of readable content Deploy at scale on
                        the.
                      </p>
                      <p
                        className="d-flex align-items-center justify-content-center pt-4"
                        style={{ color: "#0D1E67", gap: 15 }}
                      >
                        {" "}
                        Read More <i className="fa-solid fa-angle-right" />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                  <div className="mt-lg-0 mt-5">
                    <div className="Process-cards hover-cards">
                      <div
                        className="d-flex justify-content-center"
                        style={{ marginTop: "-30px" }}
                      >
                        <img src="/images/02.png" alt="" />
                      </div>
                      <h5 className="py-4">Dedicated Cloud</h5>
                      <p>
                        {" "}
                        It is a long established fact that a reader will be
                        distracted by the of readable content Deploy at scale on
                        the.
                      </p>
                      <p
                        className="d-flex align-items-center justify-content-center pt-4"
                        style={{ color: "#0D1E67", gap: 15 }}
                      >
                        {" "}
                        Read More <i className="fa-solid fa-angle-right" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* About Us */}
          <div className="sections-margin-home text-center text-lg-start ">
            <div className="container" style={{ maxWidth: 1170 }}>
              <div className="row">
                <div className="col-lg-6 mb-lg-0 mb-5">
                  <p className="badge badge-primary">About Us</p>
                  <h1 className="py-4">
                    We lead and support our customer’s cloud
                  </h1>
                  <p>
                    {" "}
                    It is a long established fact that a reader will be
                    distracted by the of readable content Deploy at scale on
                    the.
                  </p>
                  <div className="row py-4">
                    <div className="col-lg-6">
                      <div
                        className="d-flex align-items-center justify-content-lg-start justify-content-center"
                        style={{ gap: 10 }}
                      >
                        <i
                          className="fa-solid fa-check"
                          style={{ color: "#007BFC" }}
                        />
                        <p>Boost SEO ranking</p>
                      </div>
                      <div
                        className="d-flex align-items-center py-2 justify-content-lg-start justify-content-center"
                        style={{ gap: 10 }}
                      >
                        <i
                          className="fa-solid fa-check"
                          style={{ color: "#007BFC" }}
                        />
                        <p>Social Sharing</p>
                      </div>
                      <div
                        className="d-flex align-items-center justify-content-lg-start justify-content-center"
                        style={{ gap: 10 }}
                      >
                        <i
                          className="fa-solid fa-check"
                          style={{ color: "#007BFC" }}
                        />
                        <p>Marketing</p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div
                        className="d-flex align-items-center justify-content-lg-start justify-content-center"
                        style={{ gap: 10 }}
                      >
                        <i
                          className="fa-solid fa-check"
                          style={{ color: "#007BFC" }}
                        />
                        <p>Retention</p>
                      </div>
                      <div
                        className="d-flex align-items-center py-2 justify-content-lg-start justify-content-center"
                        style={{ gap: 10 }}
                      >
                        <i
                          className="fa-solid fa-check"
                          style={{ color: "#007BFC" }}
                        />
                        <p>Visual Reviews</p>
                      </div>
                      <div
                        className="d-flex align-items-center justify-content-lg-start justify-content-center"
                        style={{ gap: 10 }}
                      >
                        <i
                          className="fa-solid fa-check"
                          style={{ color: "#007BFC" }}
                        />
                        <p>Reviews Generation</p>
                      </div>
                    </div>
                  </div>
                  <button
                    className="blue-btn mt-2"
                    type="submit"
                    style={{ height: "auto", padding: "9px 30px 10px 30px" }}
                  >
                    Read more
                  </button>
                </div>
                <div className="col-lg-6">
                  <div className="row text-center">
                    <div className="col-sm-6">
                      <div className="Process-cards">
                        <h1
                          className="py-4"
                          style={{ color: "#007BFC", fontWeight: 500 }}
                        >
                          50 +
                        </h1>
                        <h5>Engineers and Developers</h5>
                      </div>
                    </div>
                    <div className="col-sm-6 mt-sm-0 mt-4">
                      <div className="Process-cards">
                        <h1
                          className="py-4"
                          style={{ color: "#007BFC", fontWeight: 500 }}
                        >
                          35 m +
                        </h1>
                        <h5>Instances Deployed</h5>
                      </div>
                    </div>
                    <div className="col-sm-6 mt-4">
                      <div className="Process-cards">
                        <h1
                          className="py-4"
                          style={{ color: "#007BFC", fontWeight: 500 }}
                        >
                          100 k +
                        </h1>
                        <h5>Customers</h5>
                      </div>
                    </div>
                    <div className="col-sm-6 mt-4">
                      <div className="Process-cards">
                        <h1
                          className="py-4"
                          style={{ color: "#007BFC", fontWeight: 500 }}
                        >
                          15 m +
                        </h1>
                        <h5>Support answers per month</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Our Services */}
          <div
            className="text-center sections-margin-home"
            style={{ position: "relative" }}
          >
            <div className="absolute-image d-lg-flex d-none">
              <img src="/images/shape1.png" alt="" />
            </div>
            <div className="container" style={{ maxWidth: 1170 }}>
              <p className="badge badge-primary">Our Services</p>
              <h1 className="pb-5 pt-3">Powerful Control Panel &amp; APIs</h1>
              <div className="row pt-5">
                <div className="col-lg-4">
                  <ul className="nav nav-pills" id="tabMenu">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="tab1-tab"
                        data-toggle="pill"
                        href="#tab1"
                        role="tab"
                        aria-controls="tab1"
                        aria-selected="true"
                        style={{ position: "relative", overflow: "hidden" }}
                      >
                        <img src="/images/02.png" alt="" />
                        <p>One Click Deploy</p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="tab2-tab"
                        data-toggle="pill"
                        href="#tab2"
                        role="tab"
                        aria-controls="tab2"
                        aria-selected="false"
                        style={{ position: "relative", overflow: "hidden" }}
                      >
                        <img src="/images/02.png" alt="" />
                        <p>Powerful API </p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="tab3-tab"
                        data-toggle="pill"
                        href="#tab3"
                        role="tab"
                        aria-controls="tab3"
                        aria-selected="false"
                        style={{ position: "relative", overflow: "hidden" }}
                      >
                        <img src="/images/02.png" alt="" />
                        <p>Easy Management </p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="tab4-tab"
                        data-toggle="pill"
                        href="#tab4"
                        role="tab"
                        aria-controls="tab4"
                        aria-selected="false"
                        style={{ position: "relative", overflow: "hidden" }}
                      >
                        <img src="/images/02.png" alt="" />
                        <p>Help Desk Services</p>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-lg-8">
                  <div className="tab-content mt-3">
                    <div
                      className="tab-pane fade show active"
                      id="tab1"
                      role="tabpanel"
                      aria-labelledby="tab1-tab"
                    >
                      <img src="/images/05.jpg" alt="Image 1" />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="tab2"
                      role="tabpanel"
                      aria-labelledby="tab2-tab"
                    >
                      <img src="/images/05.jpg" alt="Image 2" />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="tab3"
                      role="tabpanel"
                      aria-labelledby="tab3-tab"
                    >
                      <img src="/images/05.jpg" alt="Image 3" />
                    </div>
                    <div
                      className="tab-pane fade"
                      id="tab4"
                      role="tabpanel"
                      aria-labelledby="tab4-tab"
                    >
                      <img src="/images/05.jpg" alt="Image 4" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Pricing */}
          <div className="text-center sections-margin-home">
            <div className="container" style={{ maxWidth: 1170 }}>
              <p className="badge badge-primary">Pricing</p>
              <h1 className="pb-4 pt-3">Plans &amp; Pricing</h1>
              <div className="row mt-5">
                <div className="col-lg-4 col-sm-6">
                  <div className="Process-cards Pricing-cards p-0">
                    <div className="Top-card-portion">
                      <h6>BASIC</h6>
                      <h2 className="py-4">Free</h2>
                      <p>
                        {" "}
                        It is a long established fact that a reader will be
                        distracted
                      </p>
                    </div>
                    <div className="Bottom-card-portion">
                      <p> Feedback System</p>
                      <p className="py-2"> Social Integration</p>
                      <p> Push Notifications</p>
                      <p className="py-2"> Relevant App Content</p>
                      <p> Log-in via Social Media</p>
                      <button
                        className="blue-btn mt-5"
                        type="submit"
                        style={{
                          height: "auto",
                          padding: "9px 30px 10px 30px",
                        }}
                      >
                        Read More
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 mt-sm-0 mt-5">
                  <div
                    className="Process-cards Pricing-cards p-0"
                    style={{ borderTop: "10px solid #007BFC" }}
                  >
                    <div className="Top-card-portion">
                      <h6 style={{ color: "#007BFC" }}>Premium</h6>
                      <h2 className="py-4">$12/mo</h2>
                      <p>
                        {" "}
                        It is a long established fact that a reader will be
                        distracted
                      </p>
                    </div>
                    <div className="Bottom-card-portion Bottom-card-portion-two">
                      <p> Feedback System</p>
                      <p className="py-2"> Social Integration</p>
                      <p> Push Notifications</p>
                      <p className="py-2"> Relevant App Content</p>
                      <p> Log-in via Social Media</p>
                      <button
                        className="blue-btn mt-5"
                        type="submit"
                        style={{
                          height: "auto",
                          padding: "9px 30px 10px 30px",
                        }}
                      >
                        Read More
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 mt-lg-0 mt-5">
                  <div className="Process-cards Pricing-cards p-0">
                    <div className="Top-card-portion">
                      <h6>Business</h6>
                      <h2 className="py-4">$38/mo</h2>
                      <p>
                        {" "}
                        It is a long established fact that a reader will be
                        distracted
                      </p>
                    </div>
                    <div className="Bottom-card-portion">
                      <p> Feedback System</p>
                      <p className="py-2"> Social Integration</p>
                      <p> Push Notifications</p>
                      <p className="py-2"> Relevant App Content</p>
                      <p> Log-in via Social Media</p>
                      <button
                        className="blue-btn mt-5"
                        type="submit"
                        style={{
                          height: "auto",
                          padding: "9px 30px 10px 30px",
                        }}
                      >
                        Read More
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* OUR KEY */}
          <div className="text-center sections-margin-home">
            <div className="container" style={{ maxWidth: 1170 }}>
              <p className="badge badge-primary">OUR KEY</p>
              <h1 className="pb-4 pt-3">Our key differentiators</h1>
              <div className="row mt-5 text-start">
                <div className="col-lg-4 col-sm-6">
                  <div className="key-cards">
                    <div className="p-4 d-flex">
                      <img
                        src="/images/01.png"
                        alt=""
                        style={{ width: 67, height: 57 }}
                      />
                      <div>
                        <h4>Geographic Footprint</h4>
                        <p>
                          It is a long established fact that a reader will be
                          distracted by the of readable content.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                  <div className="key-cards">
                    <div className="p-4 d-flex">
                      <img
                        src="/images/01.png"
                        alt=""
                        style={{ width: 67, height: 57 }}
                      />
                      <div>
                        <h4>One Click Apps</h4>
                        <p>
                          It is a long established fact that a reader will be
                          distracted by the of readable content.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                  <div className="key-cards">
                    <div className="p-4 d-flex">
                      <img
                        src="/images/01.png"
                        alt=""
                        style={{ width: 67, height: 57 }}
                      />
                      <div>
                        <h4>Full Resource Control </h4>
                        <p>
                          It is a long established fact that a reader will be
                          distracted by the of readable content.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                  <div className="key-cards">
                    <div className="p-4 d-flex">
                      <img
                        src="/images/01.png"
                        alt=""
                        style={{ width: 67, height: 57 }}
                      />
                      <div>
                        <h4>Upload ISO / Mount ISO</h4>
                        <p>
                          It is a long established fact that a reader will be
                          distracted by the of readable content.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                  <div className="key-cards">
                    <div className="p-4 d-flex">
                      <img
                        src="/images/01.png"
                        alt=""
                        style={{ width: 67, height: 57 }}
                      />
                      <div>
                        <h4>Linux, Windows and BSD</h4>
                        <p>
                          It is a long established fact that a reader will be
                          distracted by the of readable content.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6">
                  <div className="key-cards">
                    <div className="p-4 d-flex">
                      <img
                        src="/images/01.png"
                        alt=""
                        style={{ width: 67, height: 57 }}
                      />
                      <div>
                        <h4>No Long Term Contracts</h4>
                        <p>
                          It is a long established fact that a reader will be
                          distracted by the of readable content.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Your Applications */}
          <div className="text-center sections-margin-home">
            <div className="fixed-bg-image">
              <div className="container" style={{ maxWidth: 1170 }}>
                <h1 className="pb-4 pt-3">Your Applications </h1>
                <p>
                  It is a long established fact that a reader will be distracted
                  by the of readable content Deploy.
                </p>
                <button
                  className="btn py-2 px-4 mt-5 font-weight-bold"
                  type="submit"
                  style={{ border: "3px solid #007bfc", color: "#007bfc" }}
                >
                  View Benchmarks
                </button>
              </div>
            </div>
          </div>
          {/* Testimonial */}
          <div className="sections-margin-home">
            <div className="container" style={{ maxWidth: 1170 }}>
              <div className="row">
                <div className="col-lg-4 col-md-12 pb-lg-0 pb-3 text-lg-start text-center">
                  <p className="badge badge-primary">Testimonial</p>
                  <h1 className="py-3">Some Awesome Words By Our Customers</h1>
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                </div>
                <div className="col-lg-8">
                  <Carosal />
                  {/* Carousel */}
                  {/* <div className="owl-carousel owl-theme">
                  <div className="item">
                    <div className="Process-cards p-md-5 p-3">
                      <p>
                        Text of the printing and typesetting industry. Lorem Ipsum
                        has been the industry’s standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and
                        scrambled.
                      </p>
                      <div
                        className="d-flex align-items-center pt-5"
                        style={{ gap: 15 }}
                      >
                        <div className="rounded-image">
                          <img src="/images/03.png" alt="" />
                        </div>
                        <div>
                          <h6
                            className="mb-1"
                            style={{ color: "#007BFC", fontSize: 18 }}
                          >
                            Rinks Cooper
                          </h6>
                          <p style={{ fontSize: 14 }}>Designer , Qluod</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="Process-cards p-5">
                      <p>
                        Text of the printing and typesetting industry. Lorem Ipsum
                        has been the industry’s standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and
                        scrambled.
                      </p>
                      <div
                        className="d-flex align-items-center pt-5"
                        style={{ gap: 15 }}
                      >
                        <div className="rounded-image">
                          <img src="/images/03.png" alt="" />
                        </div>
                        <div>
                          <h6
                            className="mb-1"
                            style={{ color: "#007BFC", fontSize: 18 }}
                          >
                            Rinks Cooper
                          </h6>
                          <p style={{ fontSize: 14 }}>Designer , Qluod</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="item">
                    <div className="Process-cards p-5">
                      <p>
                        Text of the printing and typesetting industry. Lorem Ipsum
                        has been the industry’s standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and
                        scrambled.
                      </p>
                      <div
                        className="d-flex align-items-center pt-5"
                        style={{ gap: 15 }}
                      >
                        <div className="rounded-image">
                          <img src="/images/03.png" alt="" />
                        </div>
                        <div>
                          <h6
                            className="mb-1"
                            style={{ color: "#007BFC", fontSize: 18 }}
                          >
                            Rinks Cooper
                          </h6>
                          <p style={{ fontSize: 14 }}>Designer , Qluod</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* Blog */}
          <div className="text-center sections-margin">
            <div className="container" style={{ maxWidth: 1170 }}>
              <p className="badge badge-primary">Blog</p>
              <h1 className="pb-4 pt-3">Recent Blog</h1>
              <div className="row mt-5 text-start">
                <div className="col-lg-4 col-sm-6">
                  <div className="card blogs-card">
                    <div className="Img-blog">
                      <img src="/images/04.png" alt="" />
                    </div>
                    <div className="bottom-card px-3 pb-3">
                      <div className="marketing-badge-top">
                        <badge className="marketing-badge">MARKETING</badge>
                      </div>
                      <div
                        className="d-flex align-items-center py-3"
                        style={{ fontSize: 14, color: "#007BFC" }}
                      >
                        <i className="fa-solid fa-calendar-days" />
                        <p className="ps-2">February 17, 2020</p>
                      </div>
                      <h5>Build Construction</h5>
                      <p className="pb-3 pt-1">
                        It is a long established fact that a reader will be
                        distracted by the readable.
                      </p>
                      <p
                        className="d-flex align-items-center"
                        style={{ color: "#007bfc", gap: 15 }}
                      >
                        {" "}
                        Read More <i className="fa-solid fa-angle-right" />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 mt-sm-0 mt-5">
                  <div className="card blogs-card">
                    <div className="Img-blog">
                      <img src="/images/04.png" alt="" />
                    </div>
                    <div className="bottom-card px-3 pb-3">
                      <div className="marketing-badge-top">
                        <badge className="marketing-badge">MARKETING</badge>
                      </div>
                      <div
                        className="d-flex align-items-center py-3"
                        style={{ fontSize: 14, color: "#007BFC" }}
                      >
                        <i className="fa-solid fa-calendar-days" />
                        <p className="ps-2">February 17, 2020</p>
                      </div>
                      <h5>Official Terraform Provider</h5>
                      <p className="pb-3 pt-1">
                        It is a long established fact that a reader will be
                        distracted by the readable.
                      </p>
                      <p
                        className="d-flex align-items-center"
                        style={{ color: "#007bfc", gap: 15 }}
                      >
                        {" "}
                        Read More <i className="fa-solid fa-angle-right" />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-6 mt-lg-0 mt-5">
                  <div className="card  blogs-card">
                    <div className="Img-blog">
                      <img src="/images/04.png" alt="" />
                    </div>
                    <div className="bottom-card px-3 pb-3">
                      <div className="marketing-badge-top">
                        <badge className="marketing-badge">MARKETING</badge>
                      </div>
                      <div
                        className="d-flex align-items-center py-3"
                        style={{ fontSize: 14, color: "#007BFC" }}
                      >
                        <i className="fa-solid fa-calendar-days" />
                        <p className="ps-2">February 17, 2020</p>
                      </div>
                      <h5>CCPA Compliance Update</h5>
                      <p className="pb-3 pt-1">
                        It is a long established fact that a reader will be
                        distracted by the readable.
                      </p>
                      <p
                        className="d-flex align-items-center"
                        style={{ color: "#007bfc", gap: 15 }}
                      >
                        {" "}
                        Read More <i className="fa-solid fa-angle-right" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Footer-portion */}
        </div>
        {/* jQuery (optional, if needed) */}
        {/* Bootstrap JS Bundle (includes Popper.js) */}
        {/* AOS Animation */}
        {/* jQuery */}
        {/* Owl Carousel JS */}
      </>
    </div>
  );
};

export default Home;
