import logo from './logo.svg';
import './styles.css';
import './Global.scss';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Home from './Components/Home';
import Services from './Screens/Services';
import About from './Components/About';
import Team from './Screens/Team';
import Pricing from './Screens/Pricing';
import Clients from './Screens/Clients';
import FAQ from './Screens/FAQ';
import Portfolio from './Components/Portfolio';
import Blog from './Components/Blog';
import Contactus from './Components/Contactus';
import Login from './Components/Login';
import Signup from './Components/Signup';
import Navbar from './Components/Navbar';
import Foter from './Components/Foter';
import Deploy from './Components/Deploy';
import Console from './Components/console';
import Newscreen from './Components/Newscreen';

// import 

function App() {
  const location = useLocation();
  console.log("🚀 ~ App ~ location:", location)
  const isDeployRoute = location.pathname === '/deploy';


  return (
    <div className="App">
      {!isDeployRoute && <Navbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/Services" element={<Services />} />
        <Route path="/Team" element={<Team />} />
        <Route path="/Pricing" element={<Pricing />} />
        <Route path="/Clients" element={<Clients />} />
        <Route path="/FAQ" element={<FAQ />} />
        <Route path="/Portfolio" element={<Portfolio />} />
        <Route path="/Blog" element={<Blog />} />
        <Route path="/Contactus" element={<Contactus />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/Signup" element={<Signup />} />
        <Route path="/deploy" element={<Deploy />} />
        <Route path="/consoleOld" element={<Console />} />
        <Route path="/console" element={<Newscreen />} />

      </Routes>
      {!isDeployRoute && <Foter />}
    </div>
  );
}

export default App;
