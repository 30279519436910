import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

const NavbarDropdown = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleToggle = (isOpen, event) => {
    setShowDropdown(isOpen);
  };

  const handleClose = () => {
    setShowDropdown(false);
  };

  return (
    <Dropdown show={showDropdown} onToggle={handleToggle}>
      <Dropdown.Toggle
        variant="success"
        id="dropdown-basic"
        className="dropdown-btn-1"
      >
        Pages
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item as={Link} to="/Services" onClick={handleClose}>
          Services
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/Team" onClick={handleClose}>
          Team
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/Pricing" onClick={handleClose}>
          Pricing
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/Clients" onClick={handleClose}>
          Clients
        </Dropdown.Item>
        <Dropdown.Item as={Link} to="/FAQ" onClick={handleClose}>
          FAQ
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NavbarDropdown;
