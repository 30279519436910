import React, { useState, useEffect } from "react";
import "./deploy.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Deploy = () => {
    const [platform, setPlatform] = useState("");
    const [service, setService] = useState("");
    const [infra, setInfra] = useState("create");
    const [etl, setEtl] = useState("create");
    const [prefix, setPrefix] = useState("");
    const [services, setServices] = useState([]);
    const [logs, setLogs] = useState("Deploying...");
    const [showLogs, setShowLogs] = useState(false);
    const [isUserApproved, setIsUserApproved] = useState(true); // New state to track user approval

    // Get the token from localStorage
    const token = JSON.parse(localStorage.getItem("app-access-token"));
    const user = JSON.parse(localStorage.getItem("user"));

    useEffect(() => {
        if (platform) {
            fetch(
                `${process.env.REACT_APP_BASE_URL_MAIN}/services?platform=${platform}`,
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )
                .then((response) => response.json())
                .then((data) => {
                    setServices(data.services);
                })
                .catch((error) => {
                    toast.error(`Error fetching services: ${error.message}`);
                });
        } else {
            setServices([]);
        }
    }, [platform, token]);

    const handleDeploy = (e) => {
        e.preventDefault();
        const data = {
            platform,
            service,
            infra,
            etl,
            prefix,
        };
        if (user?.user_metadata && user.user_metadata.approve === 0) {
            toast.error("Your account not approve plz contact admin.");
            return;
        }

        fetch(`${process.env.REACT_APP_BASE_URL_MAIN}/deploy`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`, // Set Authorization header
            },
            body: JSON.stringify(data),
        }).then(response => response.json())
            .then(data => {
                if (data.success) {
                    setShowLogs(true);
                    toast.success("Deployment started successfully!");
                    const protocol = window.location.protocol === 'https:' ? 'wss:' : 'ws:';
                    const ws = new WebSocket(`${protocol}//${window.location.host}`);
                    ws.onmessage = (event) => {
                        setLogs((prevLogs) => prevLogs + "\n" + event.data);
                    };
                    ws.onclose = () => {
                        setLogs((prevLogs) => prevLogs + "\nDeployment finished.");
                        toast.success("Deployment finished successfully!");
                    };
                }
            });
    };
    return (
        <div className="deploy-page-main">
            <div className="container">
                <h1>GlobalCloud Sol Deployment</h1>
                <form onSubmit={handleDeploy}>
                    <div className="form-group">
                        <label htmlFor="platform">Platform:</label>
                        <select
                            id="platform"
                            name="platform"
                            value={platform}
                            onChange={(e) => setPlatform(e.target.value)}
                        >
                            <option value="">Select a platform</option>
                            <option value="gcp">GCP</option>
                            <option value="aws">AWS</option>
                            <option value="azure">Azure</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="service">Service:</label>
                        <select
                            id="service"
                            name="service"
                            value={service}
                            onChange={(e) => setService(e.target.value)}
                        >
                            <option value="">Select a service</option>
                            {services.map((srv, index) => (
                                <option key={index} value={srv}>
                                    {srv}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="infra">Infra Action:</label>
                        <select
                            id="infra"
                            name="infra"
                            value={infra}
                            onChange={(e) => setInfra(e.target.value)}
                        >
                            <option value="create">Create</option>
                            <option value="destroy">Destroy</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="etl">ETL Action:</label>
                        <select
                            id="etl"
                            name="etl"
                            value={etl}
                            onChange={(e) => setEtl(e.target.value)}
                        >
                            <option value="create">Create</option>
                            <option value="destroy">Destroy</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="prefix">Prefix:</label>
                        <input
                            type="text"
                            id="prefix"
                            name="prefix"
                            value={prefix}
                            onChange={(e) => setPrefix(e.target.value)}
                        />
                    </div>
                    <button type="submit" className="btn">
                        Deploy
                    </button>
                </form>
                {showLogs && (
                    <div id="logsContainer">
                        <h2>Deployment Logs</h2>
                        <pre id="logs" className="logs">
                            {logs}
                        </pre>
                    </div>
                )}
            </div>
            {/* <ToastContainer /> */}
        </div>
    );
};

export default Deploy;
