import React from 'react'

function RecentBlog() {
  return (
    <div>
        <div className="container">
  <div className="row">
    <div className="col-md-4">
      <div className="card blogs-card">
        <div className="Img-blog">
          <img src="/images/04.png" alt="" />
        </div>
        <div className="bottom-card px-3 pb-3">
          <div className="marketing-badge-top">
            <span className="marketing-badge">MARKETING</span>
          </div>
          <div
            className="d-flex align-items-center py-3"
            style={{ fontSize: 14, color: "#007BFC" }}
          >
            <i className="fa-solid fa-calendar-days" />
            <p className="ps-2">February 17, 2020</p>
          </div>
          <h5>Official Terraform Provider</h5>
          <p className="pb-3 pt-1">
            It is a long established fact that a reader will be
            distracted by the readable.
          </p>
          <p
            className="d-flex align-items-center"
            style={{ color: "#007bfc", gap: 15 }}
          >
            Read More <i className="fa-solid fa-angle-right" />
          </p>
        </div>
      </div>
    </div>

    <div className="col-md-4">
      <div className="card blogs-card">
        <div className="Img-blog">
          <img src="/images/04.png" alt="" />
        </div>
        <div className="bottom-card px-3 pb-3">
          <div className="marketing-badge-top">
            <span className="marketing-badge">MARKETING</span>
          </div>
          <div
            className="d-flex align-items-center py-3"
            style={{ fontSize: 14, color: "#007BFC" }}
          >
            <i className="fa-solid fa-calendar-days" />
            <p className="ps-2">February 17, 2020</p>
          </div>
          <h5>Official Terraform Provider</h5>
          <p className="pb-3 pt-1">
            It is a long established fact that a reader will be
            distracted by the readable.
          </p>
          <p
            className="d-flex align-items-center"
            style={{ color: "#007bfc", gap: 15 }}
          >
            Read More <i className="fa-solid fa-angle-right" />
          </p>
        </div>
      </div>
    </div>

    <div className="col-md-4">
      <div className="card blogs-card">
        <div className="Img-blog">
          <img src="/images/04.png" alt="" />
        </div>
        <div className="bottom-card px-3 pb-3">
          <div className="marketing-badge-top">
            <span className="marketing-badge">MARKETING</span>
          </div>
          <div
            className="d-flex align-items-center py-3"
            style={{ fontSize: 14, color: "#007BFC" }}
          >
            <i className="fa-solid fa-calendar-days" />
            <p className="ps-2">February 17, 2020</p>
          </div>
          <h5>Official Terraform Provider</h5>
          <p className="pb-3 pt-1">
            It is a long established fact that a reader will be
            distracted by the readable.
          </p>
          <p
            className="d-flex align-items-center"
            style={{ color: "#007bfc", gap: 15 }}
          >
            Read More <i className="fa-solid fa-angle-right" />
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

    </div>
  )
}

export default RecentBlog