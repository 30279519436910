import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Button,
    Navbar,
    Nav,
} from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./console.css";

const Console = () => {
    const [platform, setPlatform] = useState("");
    const [selectedService, setSelectedService] = useState(null);
    const [infra, setInfra] = useState("create");
    const [etl, setEtl] = useState("create");
    const [prefix, setPrefix] = useState("");
    const [services, setServices] = useState([]);
    const [logs, setLogs] = useState("Deploying...");
    const [showLogs, setShowLogs] = useState(false);
    const [isUserApproved, setIsUserApproved] = useState(true);

    const token = JSON.parse(localStorage.getItem("app-access-token"));
    const user = JSON.parse(localStorage.getItem("user"));

    useEffect(() => {
        if (platform) {
            fetch(
                `${process.env.REACT_APP_BASE_URL_MAIN}/services?platform=${platform}`,
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    },
                }
            )
                .then((response) => {
                    if (!response.ok) {
                        throw new Error(`HTTP error! status: ${response.status}`);
                    }
                    return response.json();
                })
                .then((data) => {
                    if (data.error === "Unauthorized") {
                        throw new Error("Unauthorized access. Please check your token.");
                    }
                    setServices(data.services);
                })
                .catch((error) => {
                    toast.error(` Unauthorized `);
                });
        } else {
            setServices([]);
        }
    }, [platform, token]);


    const handleDeploy = (e) => {
        e.preventDefault();

        if (!selectedService) {
            toast.error("Please select a service before deploying.");
            return;
        }

        const payload = {
            service: selectedService,
            infra,
            etl,
            prefix,
            platform
        };

        if (user?.user_metadata && user.user_metadata.approve === 0) {
            toast.error("Your account is not approved. Please contact admin.");
            return;
        }

        fetch(`${process.env.REACT_APP_BASE_URL_MAIN}/deploy`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(payload),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    setShowLogs(true);
                    toast.success("Deployment started successfully!");
                    const protocol =
                        window.location.protocol === "https:" ? "wss:" : "ws:";
                    const ws = new WebSocket(`${protocol}//${window.location.host}`);
                    ws.onmessage = (event) => {
                        setLogs((prevLogs) => prevLogs + "\n" + event.data);
                    };
                    ws.onclose = () => {
                        setLogs((prevLogs) => prevLogs + "\nDeployment finished.");
                        toast.success("Deployment finished successfully!");
                    };
                }
            });
    };

    const handleDrop = (srv) => {
        setSelectedService(srv);
    };

    return (
        <div className="no-padding no-margin">
            <div className="main_flex_box">
                <div className="bg-light p-0 sidebar">
                    <div className="sidebar_navbar">
                        <Button
                            eventKey="home"
                            title="GCP"
                            onClick={() => setPlatform("gcp")}
                        >
                            {/* GCP */}
                            <img src="/images/googl-removebg-preview.png" />
                        </Button>
                        <Button
                            eventKey="profile"
                            title="AWS"
                            onClick={() => setPlatform("aws")}
                        >
                            {/* AWS */}
                            <img src="/images/aws-removebg-preview.png" />
                        </Button>
                        <Button
                            eventKey="contact"
                            title="Azure"
                            onClick={() => setPlatform("azure")}
                        >
                            {/* Azure */}
                            <img src="/images/azure-app-service.png" />
                        </Button>
                    </div>

                    <Container className="mt-4">
                        <div className="sidebar_tab_content">
                            <Row className="g-4">
                                {services && services?.map((srv, index) => (
                                    <Col
                                        md={4}
                                        key={index}
                                        className="text-center p-0"
                                        draggable
                                        onDragStart={(e) => e.dataTransfer.setData("service", srv)}
                                        onDragEnd={() => handleDrop(srv)}
                                    >
                                        <Button className="service-btn">
                                            <img
                                                src={`/images/${srv}.png`}
                                                // src={`/images/${srv.replace(/-/g, "_")}.png`}
                                                alt={srv}
                                                className="service-img"
                                            />
                                            <h5 className="service-text">{srv}</h5>
                                        </Button>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </Container>

                    {showLogs && (
                        <div id="logsContainer">
                            <h2>Deployment Logs</h2>
                            <pre id="logs" className="logs">
                                {logs}
                            </pre>
                        </div>
                    )}
                </div>

                {/* Sidebar with services */}
                <div className="text-white p-0 main-content">
                    <Navbar className="nav-container" expand="lg">
                        <Container className="nav-container">
                            <Navbar.Brand href="#home">MyApp</Navbar.Brand>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                            <Navbar.Collapse id="basic-navbar-nav">
                                <Nav className="ms-auto">
                                    <Button className="nav-btn" onClick={handleDeploy}>
                                        <img
                                            src="/images/deploy-removebg-preview.png"
                                            alt="Deploy"
                                            className="btn-img"
                                        />
                                        <br />
                                        Deploy
                                    </Button>
                                    <Button className="nav-btn">
                                        <img
                                            src="/images/import2-removebg-preview.png"
                                            alt="Import"
                                            className="btn-img1 btn-img"
                                        />
                                        <br />
                                        Import
                                    </Button>
                                </Nav>
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>

                    <div className="Drop-services-area_main py-4">
                        {/* Drop area for services */}
                        <Container>
                            <div className="bg-color-box">
                                <div
                                    className="drop-area"
                                    onDragOver={(e) => e.preventDefault()}
                                    onDrop={(e) => {
                                        const srv = e.dataTransfer.getData("service");
                                        handleDrop(srv);
                                    }}
                                >
                                    {/* <div className="row" style={{minHeight:"300px"}}> */}
                                    {/* <div className="col-lg-3 m-auto">
                                        <div className="Services_box">
                                            <h4>Drop Service Here</h4>
                                        </div>
                                    </div> */}

                                    {/* <div className="col-lg-9 m-auto"> */}
                                    {selectedService && (
                                        <div className="dropped-service row" style={{ minHeight: "300px" }}>
                                            <div className="col-lg-4 m-auto">
                                                <div className="Services_box">
                                                    <h5>{selectedService}</h5>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 m-auto">
                                                <div className="selectors_flexbox">
                                                    <div className="form-group Services_box blue">
                                                        <label htmlFor="infra">Infra Action:</label>
                                                        <select
                                                            id="infra"
                                                            name="infra"
                                                            value={infra}
                                                            onChange={(e) => setInfra(e.target.value)}
                                                        >
                                                            <option value="create">Create</option>
                                                            <option value="destroy">Destroy</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group Services_box purple">
                                                        <label htmlFor="etl">ETL Action:</label>
                                                        <select
                                                            id="etl"
                                                            name="etl"
                                                            value={etl}
                                                            onChange={(e) => setEtl(e.target.value)}
                                                        >
                                                            <option value="create">Create</option>
                                                            <option value="destroy">Destroy</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 m-auto">
                                                <div className="form-group Services_box red">
                                                    <label htmlFor="prefix">Prefix:</label>
                                                    <input
                                                        type="text"
                                                        id="prefix"
                                                        name="prefix"
                                                        value={prefix}
                                                        onChange={(e) => setPrefix(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {/* </div> */}
                                    {/* </div> */}
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Console;
