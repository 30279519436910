import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { ApiRequests } from "../service/ApiRequests";
import { supabase } from "../supabaseClient";
import NavbarDropdown from "./NavbarDropdown";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";

export default function Navbar2() {
  const [expanded, setExpanded] = useState(false);

  let [sess, setSess] = useState();
  let user = JSON.parse(localStorage.getItem("user"));
  console.log("🚀 ~ Navbar ~ user:", user);

  const navigate = useNavigate();

  useEffect(() => {
    const updateUserMetadata = async () => {
      try {
        // Get the current authenticated session
        const {
          data: { session },
          error: sessionError,
        } = await supabase.auth.getSession();
        if (sessionError) throw sessionError;

        const user = session?.user;

        if (user) {
          // Check if the approve field is already set in user_metadata
          if (!user.user_metadata.approve) {
            // Update the user metadata to set approve field to 0
            const { error: updateError } = await supabase.auth.updateUser({
              data: { approve: 0 }, // Set the approve field
            });

            if (session) {
              localStorage.setItem(
                "app-access-token",
                JSON.stringify(session.access_token)
              );
              localStorage.setItem(
                "refresh_token",
                JSON.stringify(session.refresh_token)
              );
              localStorage.setItem("user", JSON.stringify(session.user));
              // Redirect user after successful login
              // navigate('/dashboard'); // Change '/dashboard' to your desired route
            }

            if (updateError) {
              console.error(
                "Error updating user metadata:",
                updateError.message
              );
            } else {
              console.log(
                "User metadata updated successfully with approve field."
              );
            }
          } else {
            console.log("User metadata already has an approve field.");
          }
        } else {
          console.error("No authenticated user found.");
        }
      } catch (error) {
        console.error("Error during OAuth callback handling:", error.message);
      }
    };

    updateUserMetadata();
  }, []);

  useEffect(() => {
    supabase.auth.onAuthStateChange((event, session) => {
      setSess(session);
      console.log("🚀 ~ supabase.auth.onAuthStateChange ~ session:", session);
      if (session) {
        localStorage.setItem(
          "app-access-token",
          JSON.stringify(session.access_token)
        );
        localStorage.setItem(
          "refresh_token",
          JSON.stringify(session.refresh_token)
        );
        localStorage.setItem("user", JSON.stringify(session.user));
        // Redirect user after successful login
        // navigate('/dashboard'); // Change '/dashboard' to your desired route
      }
    });
  }, [navigate]);

  const handleLogout = async () => {
    try {
      // Log out using Supabase
      const { error } = await supabase.auth.signOut();

      if (error) {
        console.error("Error during logout:", error.message);
      } else {
        // Clear local storage after successful logout
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("user");
        localStorage.removeItem("app-access-token");

        // Optionally redirect or reload the page
        window.location.reload();
      }
    } catch (error) {
      console.error("Logout error:", error.message);
    }
  };

  return (
    <div className="main-nav">
      <div className="container">
        <Navbar expanded={expanded} expand="lg" bg="light" variant="light">
          <Container>
            <Navbar.Brand as={Link} to="/">
              <img src="/images/logo.png" alt="Logo" className="logo-img" />
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={() => setExpanded(expanded ? false : "expanded")}
            />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <Nav.Link as={Link} to="/" onClick={() => setExpanded(false)}>
                  Home
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/about"
                  onClick={() => setExpanded(false)}
                >
                  About Us
                </Nav.Link>
                <NavDropdown title="Pages" id="basic-nav-dropdown">
                  <NavDropdown.Item
                    as={Link}
                    to="/Services"
                    onClick={() => setExpanded(false)}
                  >
                    Services
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/Team"
                    onClick={() => setExpanded(false)}
                  >
                    Team
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/Pricing"
                    onClick={() => setExpanded(false)}
                  >
                    Pricing
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/Clients"
                    onClick={() => setExpanded(false)}
                  >
                    Clients
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/FAQ"
                    onClick={() => setExpanded(false)}
                  >
                    FAQ
                  </NavDropdown.Item>
                  {/* More dropdown items */}
                </NavDropdown>
                <Nav.Link
                  as={Link}
                  to="/portfolio"
                  onClick={() => setExpanded(false)}
                >
                  Portfolio
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/blog"
                  onClick={() => setExpanded(false)}
                >
                  Blog
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to="/contactus"
                  onClick={() => setExpanded(false)}
                >
                  Contact Us
                </Nav.Link>

                {user && (
                  <Nav.Link
                    as={Link}
                    to="/console"
                    onClick={() => setExpanded(false)}
                  >
                    Console
                  </Nav.Link>
                )}

                {/* More links */}
              </Nav>
              {!user ? (
                <Nav>
                  <Nav.Link
                    as={Link}
                    to="/login"
                    onClick={() => setExpanded(false)}
                    // className="btn py-2 px-2 my-sm-0 me-3 text-white font-weight-bold"
                    className="text-white font-weight-bold my-2 me-2"
                    style={{
                      // textDecoration: "none",
                      // color: "#fff",
                      border: "2px solid #007bfc",
                      height: "auto",
                      padding: "11px 30px 13px 30px",
                      borderRadius: 6,
                    }}
                  >
                    Login
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/signup"
                    onClick={() => setExpanded(false)}
                    className="blue-btn my-2"
                    style={{
                      height: "auto",
                      padding: "11px 30px 13px 30px",
                      borderRadius: 6,
                    }}
                  >
                    Sign Up
                  </Nav.Link>
                </Nav>
              ) : (
                <Nav>
                  <Nav.Link className="my-3">
                    {" "}
                    {JSON.parse(localStorage.getItem("user"))?.user_metadata
                      ?.userName || sess?.user?.user_metadata?.name}
                  </Nav.Link>
                  <Nav.Link
                    className="blue-btn my-2"
                    style={{
                      height: "auto",
                      padding: "11px 30px 13px 30px",
                      borderRadius: 6,
                    }}
                    onClick={handleLogout}
                  >
                    Logout
                  </Nav.Link>
                </Nav>
              )}
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </div>
  );
}
