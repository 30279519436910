import React from 'react'
import { Container } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

function CarousalClients() {

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 2000, min: 1200 },
          items: 6
        },
        desktop: {
          breakpoint: { max: 1199, min: 992 },
          items: 6
        },
        tablet: {
          breakpoint: { max: 991, min: 768 },
          items: 6
        },
        mobile: {
          breakpoint: { max: 767, min: 500 },
          items: 6
        }
      };
    
    

  return (
    <div>

<div className='Carousel_client'>
      <Container>
          <Carousel
        responsive={responsive}
           swipeable={true}
           draggable={true}
           showDots={false}
           autoPlaySpeed={3000}
           autoPlay={true}
           infinite={true} 
           loop={true}
          >
            <div>
            <div className="clients-img">
                <img src="/images/12.png" alt="" className="" />
              </div>
                  </div>
            <div>
            <div className="clients-img">
                <img src="/images/13.png" alt="" className="" />
              </div>
            </div>
            <div>
            <div className="clients-img">
                <img src="/images/14.png" alt="" className="" />
              </div>
            </div>
            <div>
            <div className="clients-img">
                <img src="/images/15.png" alt="" className="" />
              </div>
            </div>
            <div>
            <div className="clients-img">
                <img src="/images/16.png" alt="" className="" />
              </div>
            </div>
            <div>
            <div className="clients-img">
                <img src="/images/17.png" alt="" className="" />
              </div>
            </div>
 </Carousel>
      </Container>
    </div>


    </div>
  )
}

export default CarousalClients