import React from 'react'
import { Container } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

function TeamCarousal() {

    const responsive = {
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 2000, min: 1200 },
          items: 3
        },
        desktop: {
          breakpoint: { max: 1199, min: 992 },
          items: 3
        },
        tablet: {
          breakpoint: { max: 991, min: 768 },
          items: 3
        },
        mobile: {
          breakpoint: { max: 767, min: 500 },
          items: 3
        }
      };
    
    

  return (
    <div>

<div className='carousel_Team'>
      <Container>
          <Carousel
        responsive={responsive}
           swipeable={true}
           draggable={true}
           showDots={false}
        //    autoPlaySpeed={3000}
        //    autoPlay={true}
        //    infinite={true} 
        //    loop={true}
          >
            <div>
            <div className="team-card">
                  <div className="team-img-box">
                    <img src="/images/19.jpg" alt="" className="w-100" />
                    <div className="teams-hover-bg">
                      <div className="teams-icon">
                        <p className="tab-hover-icon">
                          <i className="fa-brands fa-facebook-f" />
                        </p>
                        <p className="tab-hover-icon my-2">
                          <i className="fa-brands fa-twitter" />
                        </p>
                        <p className="tab-hover-icon">
                          <i className="fa-brands fa-instagram" />
                        </p>
                      </div>
                      <div className="team-box">
                        <h5 className="mb-0">Anna Mull</h5>
                        <p>CEO</p>
                      </div>
                    </div>
                  </div>
                </div>
                  </div>
            <div>
            <div className="team-card">
                  <div className="team-img-box">
                    <img src="/images/20.jpg" alt="" className="w-100" />
                    <div className="teams-hover-bg">
                      <div className="teams-icon">
                        <p className="tab-hover-icon">
                          <i className="fa-brands fa-facebook-f" />
                        </p>
                        <p className="tab-hover-icon my-2">
                          <i className="fa-brands fa-twitter" />
                        </p>
                        <p className="tab-hover-icon">
                          <i className="fa-brands fa-instagram" />
                        </p>
                      </div>
                      <div className="team-box">
                        <h5 className="mb-0">Anna Mull</h5>
                        <p>CEO</p>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div>
            <div className="team-card">
                  <div className="team-img-box">
                    <img src="/images/21.jpg" alt="" className="w-100" />
                    <div className="teams-hover-bg">
                      <div className="teams-icon">
                        <p className="tab-hover-icon">
                          <i className="fa-brands fa-facebook-f" />
                        </p>
                        <p className="tab-hover-icon my-2">
                          <i className="fa-brands fa-twitter" />
                        </p>
                        <p className="tab-hover-icon">
                          <i className="fa-brands fa-instagram" />
                        </p>
                      </div>
                      <div className="team-box">
                        <h5 className="mb-0">Anna Mull</h5>
                        <p>CEO</p>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div>
            <div className="team-card">
                  <div className="team-img-box">
                    <img src="/images/22.jpg" alt="" className="w-100" />
                    <div className="teams-hover-bg">
                      <div className="teams-icon">
                        <p className="tab-hover-icon">
                          <i className="fa-brands fa-facebook-f" />
                        </p>
                        <p className="tab-hover-icon my-2">
                          <i className="fa-brands fa-twitter" />
                        </p>
                        <p className="tab-hover-icon">
                          <i className="fa-brands fa-instagram" />
                        </p>
                      </div>
                      <div className="team-box">
                        <h5 className="mb-0">Anna Mull</h5>
                        <p>CEO</p>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div>
            <div className="team-card">
                  <div className="team-img-box">
                    <img src="/images/18.jpg" alt="" className="w-100" />
                    <div className="teams-hover-bg">
                      <div className="teams-icon">
                        <p className="tab-hover-icon">
                          <i className="fa-brands fa-facebook-f" />
                        </p>
                        <p className="tab-hover-icon my-2">
                          <i className="fa-brands fa-twitter" />
                        </p>
                        <p className="tab-hover-icon">
                          <i className="fa-brands fa-instagram" />
                        </p>
                      </div>
                      <div className="team-box">
                        <h5 className="mb-0">Anna Mull</h5>
                        <p>CEO</p>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div>
            <div className="team-card">
                  <div className="team-img-box">
                    <img src="/images/20.jpg" alt="" className="w-100" />
                    <div className="teams-hover-bg">
                      <div className="teams-icon">
                        <p className="tab-hover-icon">
                          <i className="fa-brands fa-facebook-f" />
                        </p>
                        <p className="tab-hover-icon my-2">
                          <i className="fa-brands fa-twitter" />
                        </p>
                        <p className="tab-hover-icon">
                          <i className="fa-brands fa-instagram" />
                        </p>
                      </div>
                      <div className="team-box">
                        <h5 className="mb-0">Anna Mull</h5>
                        <p>CEO</p>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
 </Carousel>
      </Container>
    </div>


    </div>
  )
}

export default TeamCarousal