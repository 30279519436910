import React from 'react'
import PortfolioTabs from './PortfolioTabs';

function Portfolio() {
  return (
    <div>
       <div>
        <div style={{ backgroundColor: "#0d1e67", minHeight: 400 }}>
          <div className="container" style={{ maxWidth: 1170 }}>
            {/* Navbar */}
            {/* Breadcrumbs */}
            <div className="pt-5 text-center text-white">
              <h1 className="text-white pt-5">Portfolio</h1>
              <div className="d-flex align-items-center justify-content-center pt-2">
                <p>
                  <i className="fa-solid fa-house-chimney" />
                  <span className="ps-1">Home</span>
                </p>
                <p className="px-3">
                  <i className="fa-solid fa-angle-right" />
                </p>
                <p>Portfolio</p>
              </div>
            </div>
          </div>
        </div>
        {/* Tabs */}
        {/* <div className="sections-margin">
          <div className="container" style={{ maxWidth: 1400 }}>
            <div className="row">
              <div className="col-lg-12">
                <ul className="nav nav-portfolio-tabs" id="tabMenu">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="tab1-tab"
                      data-toggle="pill"
                      href="#tab1"
                      role="tab"
                      aria-controls="tab1"
                      aria-selected="true"
                    >
                      All
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="tab2-tab"
                      data-toggle="pill"
                      href="#tab2"
                      role="tab"
                      aria-controls="tab2"
                      aria-selected="false"
                    >
                      Creative
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="tab3-tab"
                      data-toggle="pill"
                      href="#tab3"
                      role="tab"
                      aria-controls="tab3"
                      aria-selected="false"
                    >
                      Design
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="tab4-tab"
                      data-toggle="pill"
                      href="#tab4"
                      role="tab"
                      aria-controls="tab4"
                      aria-selected="false"
                    >
                      Print Branding
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="tab5-tab"
                      data-toggle="pill"
                      href="#tab5"
                      role="tab"
                      aria-controls="tab5"
                      aria-selected="false"
                    >
                      Html e-Commerce
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-12">
                <div className="tab-content mt-3">
                  <div
                    className="tab-pane fade show active"
                    id="tab1"
                    role="tabpanel"
                    aria-labelledby="tab1-tab"
                  >
                    <div className="row pt-4">
                      <div className="col-lg-4">
                        <div className="tabs-card">
                          <div className="tab-img-box">
                            <img src="/images/10.png" alt="" />
                            <div className="tabs-hover-bg">
                              <div className="tab-hover-icon">
                                <i className="fa-solid fa-link" />
                              </div>
                            </div>
                          </div>
                          <div className="tab-cards-body pt-3">
                            <h5>Dignissimos wisi cillum</h5>
                            <p>Print — Branding</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="tabs-card">
                          <div className="tab-img-box">
                            <img src="/images/10.png" alt="" />
                            <div className="tabs-hover-bg">
                              <div className="tab-hover-icon">
                                <i className="fa-solid fa-link" />
                              </div>
                            </div>
                          </div>
                          <div className="tab-cards-body pt-3">
                            <h5>Dignissimos wisi cillum</h5>
                            <p>Design</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="tabs-card">
                          <div className="tab-img-box">
                            <img src="/images/10.png" alt="" />
                            <div className="tabs-hover-bg">
                              <div className="tab-hover-icon">
                                <i className="fa-solid fa-link" />
                              </div>
                            </div>
                          </div>
                          <div className="tab-cards-body pt-3">
                            <h5>Dignissimos wisi cillum</h5>
                            <p>html -eCommerce</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="tab2"
                    role="tabpanel"
                    aria-labelledby="tab2-tab"
                  >
                    <div className="row pt-4">
                      <div className="col-lg-4">
                        <div className="tabs-card">
                          <div className="tab-img-box">
                            <img src="/images/10.png" alt="" />
                            <div className="tabs-hover-bg">
                              <div className="tab-hover-icon">
                                <i className="fa-solid fa-link" />
                              </div>
                            </div>
                          </div>
                          <div className="tab-cards-body pt-3">
                            <h5>Dignissimos wisi cillum</h5>
                            <p>Print — Branding</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="tabs-card">
                          <div className="tab-img-box">
                            <img src="/images/10.png" alt="" />
                            <div className="tabs-hover-bg">
                              <div className="tab-hover-icon">
                                <i className="fa-solid fa-link" />
                              </div>
                            </div>
                          </div>
                          <div className="tab-cards-body pt-3">
                            <h5>Dignissimos wisi cillum</h5>
                            <p>Design</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="tabs-card">
                          <div className="tab-img-box">
                            <img src="/images/10.png" alt="" />
                            <div className="tabs-hover-bg">
                              <div className="tab-hover-icon">
                                <i className="fa-solid fa-link" />
                              </div>
                            </div>
                          </div>
                          <div className="tab-cards-body pt-3">
                            <h5>Dignissimos wisi cillum</h5>
                            <p>html -eCommerce</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="tab3"
                    role="tabpanel"
                    aria-labelledby="tab3-tab"
                  >
                    <div className="row pt-4">
                      <div className="col-lg-4">
                        <div className="tabs-card">
                          <div className="tab-img-box">
                            <img src="/images/10.png" alt="" />
                            <div className="tabs-hover-bg">
                              <div className="tab-hover-icon">
                                <i className="fa-solid fa-link" />
                              </div>
                            </div>
                          </div>
                          <div className="tab-cards-body pt-3">
                            <h5>Dignissimos wisi cillum</h5>
                            <p>Print — Branding</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="tabs-card">
                          <div className="tab-img-box">
                            <img src="/images/10.png" alt="" />
                            <div className="tabs-hover-bg">
                              <div className="tab-hover-icon">
                                <i className="fa-solid fa-link" />
                              </div>
                            </div>
                          </div>
                          <div className="tab-cards-body pt-3">
                            <h5>Dignissimos wisi cillum</h5>
                            <p>Design</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="tabs-card">
                          <div className="tab-img-box">
                            <img src="/images/10.png" alt="" />
                            <div className="tabs-hover-bg">
                              <div className="tab-hover-icon">
                                <i className="fa-solid fa-link" />
                              </div>
                            </div>
                          </div>
                          <div className="tab-cards-body pt-3">
                            <h5>Dignissimos wisi cillum</h5>
                            <p>html -eCommerce</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="tab4"
                    role="tabpanel"
                    aria-labelledby="tab4-tab"
                  >
                    <div className="row pt-4">
                      <div className="col-lg-4">
                        <div className="tabs-card">
                          <div className="tab-img-box">
                            <img src="/images/10.png" alt="" />
                            <div className="tabs-hover-bg">
                              <div className="tab-hover-icon">
                                <i className="fa-solid fa-link" />
                              </div>
                            </div>
                          </div>
                          <div className="tab-cards-body pt-3">
                            <h5>Dignissimos wisi cillum</h5>
                            <p>Print — Branding</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="tabs-card">
                          <div className="tab-img-box">
                            <img src="/images/10.png" alt="" />
                            <div className="tabs-hover-bg">
                              <div className="tab-hover-icon">
                                <i className="fa-solid fa-link" />
                              </div>
                            </div>
                          </div>
                          <div className="tab-cards-body pt-3">
                            <h5>Dignissimos wisi cillum</h5>
                            <p>Design</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="tabs-card">
                          <div className="tab-img-box">
                            <img src="/images/10.png" alt="" />
                            <div className="tabs-hover-bg">
                              <div className="tab-hover-icon">
                                <i className="fa-solid fa-link" />
                              </div>
                            </div>
                          </div>
                          <div className="tab-cards-body pt-3">
                            <h5>Dignissimos wisi cillum</h5>
                            <p>html -eCommerce</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="tab5"
                    role="tabpanel"
                    aria-labelledby="tab5-tab"
                  >
                    <div className="row pt-4">
                      <div className="col-lg-4">
                        <div className="tabs-card">
                          <div className="tab-img-box">
                            <img src="/images/10.png" alt="" />
                            <div className="tabs-hover-bg">
                              <div className="tab-hover-icon">
                                <i className="fa-solid fa-link" />
                              </div>
                            </div>
                          </div>
                          <div className="tab-cards-body pt-3">
                            <h5>Dignissimos wisi cillum</h5>
                            <p>Print — Branding</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="tabs-card">
                          <div className="tab-img-box">
                            <img src="/images/10.png" alt="" />
                            <div className="tabs-hover-bg">
                              <div className="tab-hover-icon">
                                <i className="fa-solid fa-link" />
                              </div>
                            </div>
                          </div>
                          <div className="tab-cards-body pt-3">
                            <h5>Dignissimos wisi cillum</h5>
                            <p>Design</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <div className="tabs-card">
                          <div className="tab-img-box">
                            <img src="/images/10.png" alt="" />
                            <div className="tabs-hover-bg">
                              <div className="tab-hover-icon">
                                <i className="fa-solid fa-link" />
                              </div>
                            </div>
                          </div>
                          <div className="tab-cards-body pt-3">
                            <h5>Dignissimos wisi cillum</h5>
                            <p>html -eCommerce</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <PortfolioTabs />
        {/* Footer-portion */}
      </div>
    </div>
  )
}

export default Portfolio