import React from 'react'

function Contactus() {
  return (
    <div>
        <div>
        <div style={{ backgroundColor: "#0d1e67", minHeight: 400 }}>
          <div className="container" style={{ maxWidth: 1170 }}>
            {/* Navbar */}
            {/* Breadcrumbs */}
            <div className="pt-5 text-center text-white">
              <h1 className="text-white pt-5">Contact Us</h1>
              <div className="d-flex align-items-center justify-content-center pt-2">
                <p>
                  <i className="fa-solid fa-house-chimney" />
                  <span className="ps-1">Home</span>
                </p>
                <p className="px-3">
                  <i className="fa-solid fa-angle-right" />
                </p>
                <p>Contact Us</p>
              </div>
            </div>
          </div>
        </div>
        {/* Contact cards */}
        <div className="sections-margin">
          <div className="container" style={{ maxWidth: 1250 }}>
            <div className="row">
              <div className="col-lg-4 col-sm-6">
                <div className="key-cards py-3 px-4">
                  <div className="row">
                    <div className="icon-bg col-lg-2">
                      <i className="fa-solid fa-location-dot" />
                    </div>
                    <div className="col">
                      <h5>Location</h5>
                      <p> 1234 North Avenue Luke Lane, South Bend, IN 360001 </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mt-sm-0 mt-4">
                <div className="key-cards py-3 px-4">
                  <div className="row">
                    <div className="icon-bg col-lg-2">
                      <i className="fa-solid fa-location-dot" />
                    </div>
                    <div className="col">
                      <h5>Location</h5>
                      <p> 1234 North Avenue Luke Lane, South Bend, IN 360001 </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mt-lg-0 mt-4">
                <div className="key-cards py-3 px-4">
                  <div className="row">
                    <div className="icon-bg col-lg-2">
                      <i className="fa-solid fa-location-dot" />
                    </div>
                    <div className="col">
                      <h5>Location</h5>
                      <p> 1234 North Avenue Luke Lane, South Bend, IN 360001 </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Map and form */}
        <div className="sections-margin">
          <div className="container" style={{ maxWidth: 1250 }}>
            <div className="row">
              <div className="col-lg-6">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d158876.6418960979!2d-0.11677300000000002!3d51.523351!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604b900d26973%3A0x4291f3172409ea92!2slastminute.com%20London%20Eye!5e0!3m2!1sen!2sus!4v1722863682412!5m2!1sen!2sus"
                  width="100%"
                  height="100%"
                  style={{ border: 0, minHeight: 300 }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                />
              </div>
              <div className="col-lg-6 pt-lg-0 pt-5">
                <p className="badge badge-primary">Get In Touch</p>
                <h1 className="pt-3 pb-2">Contact With US</h1>
                <p>
                  {" "}
                  It is a long established fact that a reader will be distracted.
                </p>
                <div className="row py-4">
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="input-form-box"
                      placeholder="First Name"
                    />
                  </div>
                  <div className="col-sm-6 mt-sm-0 mt-4">
                    <input
                      type="text"
                      className="input-form-box"
                      placeholder="Last Name"
                    />
                  </div>
                  <div className="col-sm-6 mt-4">
                    <input
                      type="email"
                      className="input-form-box"
                      placeholder="Email Address"
                    />
                  </div>
                  <div className="col-sm-6 mt-4">
                    {/* <input type="number" class="input-form-box" placeholder="First Name"> */}
                    <input
                      type="tel"
                      id="phone"
                      name="phone"
                      placeholder="Phone"
                      pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                      className="input-form-box"
                    />
                  </div>
                  <div className="col-lg-12">
                    <textarea
                      id="w3review"
                      name="w3review"
                      placeholder="Message"
                      rows={4}
                      className="input-form-box mt-4"
                      defaultValue={""}
                    />
                  </div>
                </div>
                <button
                  className="blue-btn"
                  type="submit"
                  style={{ height: "auto", padding: "15px 30px 16px 30px" }}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Footer-portion */}
        <div className="sections-margin" style={{ backgroundColor: "#0d1e67" }}>
          {/* Subscribe */}
          <div style={{ backgroundColor: "#0b1a58", color: "#fff" }}>
            <div className="container" style={{ maxWidth: 1170 }}>
              <div className="d-flex align-items justify-content-between flex-md-row flex-column py-5">
                <h1 className="mb-0" style={{ color: "#fff" }}>
                  Subscribe for Newsletter
                </h1>
                <div className="input-form mt-md-0 mt-4">
                  <input type="email" placeholder="Your email address" />
                  <button className="blue-btn ">Subscribe</button>
                </div>
              </div>
            </div>
          </div>
          {/* Footer */}
        </div>
      </div>
    </div>
  )
}

export default Contactus