import { createClient } from '@supabase/supabase-js';

// const supabaseUrl = process.env.NEXT_PUBLIC_SUPABASE_URL;
// const supabaseKey = process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY;





const supabaseUrl = "https://otuzvklkipsxtnhnniyc.supabase.co"
const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im90dXp2a2xraXBzeHRuaG5uaXljIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjM3MjAwMDMsImV4cCI6MjAzOTI5NjAwM30.mt_z17CKcgt0LxOVJ2QXPrfnMrPjCbh2mcR8T4kjyvE"

export const supabase = createClient(supabaseUrl, supabaseKey);
