import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Navbar, Nav } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./console.css";
import Form from "react-bootstrap/Form";
import { FaRocket } from "react-icons/fa";

const Newscreen = () => {
  const [platform, setPlatform] = useState("");
  const [selectedServices, setSelectedServices] = useState([]);
  const [infra, setInfra] = useState("create");
  const [etl, setEtl] = useState("create");
  const [prefix, setPrefix] = useState("");
  const [services, setServices] = useState([]);
  const [logs, setLogs] = useState("Deploying...");
  const [showLogs, setShowLogs] = useState(false);
  const [isUserApproved, setIsUserApproved] = useState(true);
  const [serviceStatus, setServiceStatus] = useState({});
  const [loadingStatus, setLoadingStatus] = useState({});
  const [isDeployed, setIsDeployed] = useState(false);

  const token = JSON.parse(localStorage.getItem("app-access-token"));
  const user = JSON.parse(localStorage.getItem("user"));

  // Fetch services when platform is selected
  useEffect(() => {
    if (platform) {
      fetch(
        `${process.env.REACT_APP_BASE_URL_MAIN}/services?platform=${platform}`,
        {
          headers: {
            authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setServices(data.services);
          setSelectedServices(data.services);
          const initialStatus = {};
          data.services.forEach((srv) => {
            initialStatus[srv] = "idle";
          });
          setServiceStatus(initialStatus);
          setLoadingStatus(initialStatus);
        })
        .catch((error) => {
          toast.error(`Error fetching services: ${error.message}`);
        });
    } else {
      setServices([]);
    }
  }, [platform, token]);

  // Deploy each selected service
  const handleDeploy = (e) => {
    e.preventDefault();

    if (selectedServices.length === 0) {
      toast.error("Please select at least one service before deploying.");
      return;
    }

    if (user?.user_metadata && user.user_metadata.approve === 0) {
      toast.error("Your account is not approved. Please contact admin.");
      return;
    }

    selectedServices.forEach((service) => {
      const payload = {
        service,
        infra,
        etl,
        prefix,
        platform,
      };

      setLoadingStatus((prevState) => ({
        ...prevState,
        [service]: "running",
      }));

      fetch(`${process.env.REACT_APP_BASE_URL_MAIN}/deploy`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status === "success") {
            setShowLogs(true);
            toast.success(`${service} deployment started successfully!`);
            setServiceStatus((prevState) => ({
              ...prevState,
              [service]: "success",
            }));
            setLoadingStatus((prevState) => ({
              ...prevState,
              [service]: "idle",
            }));

            // const protocol =
            //   window.location.protocol === "https:" ? "wss:" : "ws:";
            // const ws = new WebSocket(`${protocol}//${window.location.host}`);

            const isProduction = process.env.NODE_ENV == "development";
            console.log(
              "🚀 ~ .then ~ process.env.NODE_ENV:",
              process.env.NODE_ENV
            );

            const wsUrl = isProduction
              ? "wss://api.globalcloudsol.com/ws"
              : "ws://localhost:3001/ws";

            const ws = new WebSocket(wsUrl);

            ws.onmessage = (event) => {
              setLogs((prevLogs) => prevLogs + "\n" + event?.data);
            };
            ws.onclose = () => {
              setLogs(
                (prevLogs) => prevLogs + `\n${service} deployment finished.`
              );
              toast.success(`${service} deployment finished successfully!`);
            };
          } else {
            setServiceStatus((prevState) => ({
              ...prevState,
              [service]: "failure",
            }));
            setLoadingStatus((prevState) => ({
              ...prevState,
              [service]: "idle",
            }));

            toast.error(`${service} deployment failed.`);
          }
        })
        .catch(() => {
          setServiceStatus((prevState) => ({
            ...prevState,
            [service]: "failure",
          }));
          setLoadingStatus((prevState) => ({
            ...prevState,
            [service]: "idle",
          }));
          toast.error(`${service} deployment failed due to network error.`);
        });
    });

    setIsDeployed(true); // Set deployment to true after first call
    setInfra("destroy"); // Change infra to "destroy"
  };

  const handleSynchronousDeploy = async (e) => {
    e.preventDefault();

    if (selectedServices.length === 0) {
      toast.error("Please select at least one service before deploying.");
      return;
    }

    if (user?.user_metadata && user.user_metadata.approve === 0) {
      toast.error("Your account is not approved. Please contact admin.");
      return;
    }
    for (const service of selectedServices) {
      const payload = {
        service,
        infra,
        etl,
        prefix,
        platform,
      };
      setLoadingStatus((prevState) => ({
        ...prevState,
        [service]: "running",
      }));
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BASE_URL_MAIN}/deploy`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(payload),
          }
        );

        const data = await response.json();

        if (data.status === "success") {
          setShowLogs(true);
          toast.success(`${service} deployment started successfully!`);
          setServiceStatus((prevState) => ({
            ...prevState,
            [service]: "success",
          }));

          setLoadingStatus((prevState) => ({
            ...prevState,
            [service]: "idle",
          }));

          // const protocol =
          //   window.location.protocol === "https:" ? "wss:" : "ws:";
          // const ws = new WebSocket(`${protocol}//${window.location.host}`);
          const isProduction = process.env.NODE_ENV == "development";
          console.log(
            "🚀 ~ handleSynchronousDeploy ~ process.env.NODE_ENV:",
            process.env.NODE_ENV
          );

          const wsUrl = isProduction
            ? "wss://api.globalcloudsol.com"
            : "ws://localhost:3001";

          const ws = new WebSocket(wsUrl);
          ws.onmessage = (event) => {
            setLogs((prevLogs) => prevLogs + "\n" + event.data);
          };
          ws.onclose = () => {
            setLogs(
              (prevLogs) => prevLogs + `\n${service} deployment finished.`
            );
            toast.success(`${service} deployment finished successfully!`);
          };
        } else {
          setServiceStatus((prevState) => ({
            ...prevState,
            [service]: "failure",
          }));

          setLoadingStatus((prevState) => ({
            ...prevState,
            [service]: "idle",
          }));

          toast.error(`${service} deployment failed.`);
        }
      } catch (error) {
        setServiceStatus((prevState) => ({
          ...prevState,
          [service]: "failure",
        }));

        setLoadingStatus((prevState) => ({
          ...prevState,
          [service]: "idle",
        }));
        toast.error(`${service} deployment failed due to network error.`);
      }
    }

    setIsDeployed(true); // Set deployment to true after first call
    setInfra("destroy"); // Change infra to "destroy"
  };

  const handleCheckboxChange = (srv) => {
    setSelectedServices((prevSelected) =>
      prevSelected.includes(srv)
        ? prevSelected.filter((service) => service !== srv)
        : [...prevSelected, srv]
    );
  };

  const getServiceButtonClass = (srv) => {
    if (loadingStatus[srv] === "running") return "service-btn-running";

    switch (serviceStatus[srv]) {
      case "success":
        return "service-btn-success";
      case "failure":
        return "service-btn-failure";
      default:
        return "service-btn-idle";
    }
  };

  return (
    <div className="tabs_screen_main_div">
      <div className="tabs_img_box">
        <div className="tabs_flex_box">
          <Button
            eventKey="home"
            title="GCP"
            onClick={() => setPlatform("gcp")}
          >
            <img src="/images/googl-removebg-preview.png" />
          </Button>
          <Button
            eventKey="profile"
            title="AWS"
            onClick={() => setPlatform("aws")}
          >
            <img src="/images/aws-removebg-preview.png" />
          </Button>
          <Button
            eventKey="contact"
            title="Azure"
            onClick={() => setPlatform("azure")}
          >
            <img src="/images/azure-app-service.png" />
          </Button>
        </div>
        <div className="tabs_content_div">
          <Container>
            <div className="tabs-content">
              <div className="sidebar_content">
                <Row className="pb-5">
                  {services.map((srv, index) => (
                    <Col
                      key={index}
                      className="text-center p-0"
                      draggable
                      onDragStart={(e) =>
                        e.dataTransfer.setData("service", srv)
                      }
                      onDragEnd={() => handleCheckboxChange(srv)}
                    >
                      <Button
                        className={`service-btn ${getServiceButtonClass(srv)}`}
                      >
                        <div className="img_box" style={{ minHeight: "40px" }}>
                          <Form.Check
                            aria-label={`option-${index}`}
                            onChange={() => handleCheckboxChange(srv)}
                            checked={selectedServices.includes(srv)}
                          />
                          <img
                            src={`/images/${srv}.png`}
                            alt={srv}
                            className="service-img"
                          />
                        </div>

                        <h5 className="service-text">
                          {srv}{" "}
                          {loadingStatus[srv] === "running" && "Running..."}
                        </h5>
                      </Button>
                    </Col>
                  ))}
                </Row>
              </div>
              <div className="logs-container">
                {showLogs && (
                  <textarea
                    rows="15"
                    value={logs}
                    readOnly
                    style={{
                      width: "350px",
                      padding: "10px",
                      fontSize: "14px",
                      height: "400px",
                    }}
                  />
                )}
              </div>
            </div>
          </Container>
        </div>
      </div>
      {/* Conditionally render the button based on deployment status */}

      {selectedServices.length > 0 && (
        <div
          className="btns_deploy"
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "auto",
            marginBottom: "20px",
          }}
        >
          <Button
            className="deploy-btn"
            onClick={handleDeploy}
            title={isDeployed ? "Destroy" : "Deploy"}
            disabled={Object.values(loadingStatus).includes("running")}
          >
            <FaRocket />
            {isDeployed ? "Destroy (Parallel)" : "Deploy (Parallel)"}
          </Button>

          <Button
            className="deploy-btn"
            onClick={handleSynchronousDeploy}
            title={isDeployed ? "Destroy" : "Deploy"}
            disabled={Object.values(loadingStatus).includes("running")}
            style={{ marginLeft: "10px" }}
          >
            <FaRocket />
            {isDeployed ? "Destroy (Synchronous)" : "Deploy (Synchronous)"}
          </Button>
        </div>
      )}
    </div>
  );
};

export default Newscreen;
