import React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import TabCards from "./TabCards";

function PortfolioTabs() {
  return (
    <div>
        <div className="container">
      <Tabs
        defaultActiveKey="all"
        id="uncontrolled-tab-example"
        className="mb-3 tabs-line"
      >
        <Tab eventKey="all" title="All">
            
            <div className="pt-4">
          <div className="row">
            <div className="col-lg-4">
              <TabCards />
            </div>
            <div className="col-lg-4">
              <TabCards />
            </div>
            <div className="col-lg-4">
              <TabCards />
            </div>
          </div>
       </div>
        </Tab>
        <Tab eventKey="creative" title="Creative">
            <div className="pt-4">
        <div className="row">
            <div className="col-lg-4">
              <TabCards />
            </div>
            <div className="col-lg-4">
              <TabCards />
            </div>
            <div className="col-lg-4">
              <TabCards />
            </div>
          </div>
          </div>
        </Tab>
        <Tab eventKey="design" title="Design">
            <div className="pt-4">
        <div className="row">
            <div className="col-lg-4">
              <TabCards />
            </div>
            <div className="col-lg-4">
              <TabCards />
            </div>
            <div className="col-lg-4">
              <TabCards />
            </div>
          </div>
          </div>
        </Tab>
        <Tab eventKey="print branding" title="Print Branding">
            <div className="pt-4">
        <div className="row">
            <div className="col-lg-4">
              <TabCards />
            </div>
            <div className="col-lg-4">
              <TabCards />
            </div>
            <div className="col-lg-4">
              <TabCards />
            </div>
          </div>
          </div>
        </Tab>
        <Tab eventKey="html e-commerce" title="Html e-commerce">
            <div className="pt-4">
        <div className="row">
            <div className="col-lg-4">
              <TabCards />
            </div>
            <div className="col-lg-4">
              <TabCards />
            </div>
            <div className="col-lg-4">
              <TabCards />
            </div>
          </div>
          </div>
        </Tab>
      </Tabs>
      </div>
    </div>
  );
}

export default PortfolioTabs;
