import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

export default function Footer() {
  return (
    <div className="" style={{ backgroundColor: "#0d1e67" }}>
      {/* Subscribe */}
      <div style={{ backgroundColor: "#0b1a58", color: "#fff" }}>
        <div className="container" style={{ maxWidth: 1170 }}>
          <div className="d-flex align-items justify-content-between flex-md-row flex-column py-5">
            <h1 className="mb-0" style={{ color: "#fff" }}>
              Subscribe for Newsletter
            </h1>
            <div className="input-form mt-md-0 mt-4">
              <input type="email" placeholder="Your email address" />
              <button className="blue-btn">Subscribe</button>
            </div>
          </div>
        </div>
      </div>
      {/* Footer */}
      <div className="text-white footer">
        <div className="container py-5" style={{ maxWidth: 1170 }}>
          <div className="row text-lg-start text-center">
            <div className="col-lg-3">
              <img src="/images/logo.png" alt="" className="logo-img" />
              <p className="pt-4">
                It is a long established fact that a reader will be distracted by the readable content.
              </p>
            </div>
            <div className="col-lg-2">
              <h4 className="text-white mt-lg-0 mt-4">Menu</h4>
              <ul>
                <li>
                  <Link className="nav-link" to="/Home">
                    Home
                  </Link>
                </li>
                <li>
                  <Link className="nav-link" to="/About">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link className="nav-link" to="/Services">
                    Services
                  </Link>
                </li>
                <li>
                  <Link className="nav-link" to="/Blog">
                    Blog
                  </Link>
                </li>
                <li>
                  <Link className="nav-link" to="/Contact">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-2">
              <h4 className="text-white">About Us</h4>
              <ul>
                <li>
                  <Link className="nav-link" to="/About">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link className="nav-link" to="/Services">
                    Services
                  </Link>
                </li>
                <li>
                  <Link className="nav-link" to="/Contact">
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link className="nav-link" to="/Pricing-plan">
                    Pricing Plan
                  </Link>
                </li>
                <li>
                  <Link className="nav-link" to="/Clients">
                    Clients
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-2">
              <h4 className="text-white">Useful links</h4>
              <ul>
                <li>
                  <Link className="nav-link" to="/Team">
                    Our team
                  </Link>
                </li>
                <li>
                  <Link className="nav-link" to="/Pricing">
                    Pricing Plan
                  </Link>
                </li>
                <li>
                  <Link className="nav-link" to="/FAQ">
                    FAQ
                  </Link>
                </li>
                <li>
                  <Link className="nav-link" to="/Clients">
                    Clients
                  </Link>
                </li>
                <li>
                  <Link className="nav-link" to="/ContactUs">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3">
              <h4 className="text-white">Contact Us</h4>
              <div className="d-flex align-items-center justify-content-lg-start justify-content-center pt-4">
                <i className="fa-solid fa-house" />
                <p className="ps-sm-3 ps-1">
                  1234 North Avenue Luke Lane, South Bend, IN 360001
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-lg-start justify-content-center pt-4">
                <i className="fa-solid fa-envelope" />
                <p className="ps-sm-3 ps-1" style={{ wordBreak: "break-all" }}>
                  support@globalcloudservices.com
                </p>
              </div>
              <div className="d-flex align-items-center justify-content-lg-start justify-content-center pt-4">
                <i className="fa-solid fa-phone" />
                <p className="ps-sm-3 ps-1">+0123456789</p>
              </div>
            </div>
          </div>
        </div>
        <div style={{ borderTop: "1px solid #59597e" }}>
          <div className="container py-4" style={{ maxWidth: 1170 }}>
            <div className="d-flex align-items-center justify-content-sm-between justify-content-center flex-sm-row flex-column">
              <div className="d-flex align-items-center">
                <i className="fa-brands fa-instagram" />
                <i className="fa-brands fa-linkedin-in px-3" />
                <i className="fa-brands fa-twitter" />
              </div>
              <p
                className="text-white pt-lg-0 pt-2 text-sm-start text-center"
                style={{ fontSize: 14 }}
              >
                Copyright 2024 global cloud All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
