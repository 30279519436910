import React from 'react'

function Blog() {
  return (
    <div>
       <div>
        <div style={{ backgroundColor: "#0d1e67", minHeight: 400 }}>
          <div className="container" style={{ maxWidth: 1170 }}>
            {/* Navbar */}
            {/* Breadcrumbs */}
            <div className="pt-5 text-center text-white">
              <h1 className="text-white pt-5">Blog</h1>
              <div className="d-flex align-items-center justify-content-center pt-2">
                <p>
                  <i className="fa-solid fa-house-chimney" />
                  <span className="ps-1">Home</span>
                </p>
                <p className="px-3">
                  <i className="fa-solid fa-angle-right" />
                </p>
                <p>Blog</p>
              </div>
            </div>
          </div>
        </div>
        {/* Blog */}
        <div className="sections-margin">
          <div className="container" style={{ maxWidth: 1250 }}>
            <div className="row">
              <div className="col-lg-4 col-sm-6">
                <div className="card blogs-card h-100">
                  <div className="Img-blog">
                    <img src="/images/04.png" alt="" />
                  </div>
                  <div className="bottom-card px-3 pb-3">
                    <div className="marketing-badge-top">
                      <badge className="marketing-badge">MARKETING</badge>
                    </div>
                    <div
                      className="d-flex align-items-center py-3"
                      style={{ fontSize: 14, color: "#007BFC" }}
                    >
                      {/* <i class="fa-solid fa-calendar-days"></i> */}
                      <p className="ps-2">February 17, 2020</p>
                    </div>
                    <h3>Official Terraform Provider</h3>
                    <p className="pb-3 pt-1">
                      It is a long established fact that a reader will be
                      distracted by the readable.
                    </p>
                    <p
                      className="d-flex align-items-center"
                      style={{ color: "#007bfc", gap: 15 }}
                    >
                      {" "}
                      Read More <i className="fa-solid fa-angle-right" />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mt-sm-0 mt-5">
                <div className="card blogs-card h-100">
                  <div className="Img-blog">
                    <img src="/images/04.png" alt="" />
                  </div>
                  <div className="bottom-card px-3 pb-3">
                    <div className="marketing-badge-top">
                      <badge className="marketing-badge">MARKETING</badge>
                    </div>
                    <div
                      className="d-flex align-items-center py-3"
                      style={{ fontSize: 14, color: "#007BFC" }}
                    >
                      {/* <i class="fa-solid fa-calendar-days"></i> */}
                      <p className="ps-2">February 17, 2020</p>
                    </div>
                    <h3>Life Lack Meaning</h3>
                    <p className="pb-3 pt-1">
                      It is a long established fact that a reader will be
                      distracted by the readable.
                    </p>
                    <p
                      className="d-flex align-items-center"
                      style={{ color: "#007bfc", gap: 15 }}
                    >
                      {" "}
                      Read More <i className="fa-solid fa-angle-right" />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mt-lg-0 mt-5">
                <div className="card blogs-card h-100">
                  <div className="Img-blog">
                    <img src="/images/04.png" alt="" />
                  </div>
                  <div className="bottom-card px-3 pb-3">
                    <div className="marketing-badge-top">
                      <badge className="marketing-badge">MARKETING</badge>
                    </div>
                    <div
                      className="d-flex align-items-center py-3"
                      style={{ fontSize: 14, color: "#007BFC" }}
                    >
                      {/* <i class="fa-solid fa-calendar-days"></i> */}
                      <p className="ps-2">February 17, 2020</p>
                    </div>
                    <h3>Build Construction</h3>
                    <p className="pb-3 pt-1">
                      It is a long established fact that a reader will be
                      distracted by the readable.
                    </p>
                    <p
                      className="d-flex align-items-center"
                      style={{ color: "#007bfc", gap: 15 }}
                    >
                      {" "}
                      Read More <i className="fa-solid fa-angle-right" />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mt-5">
                <div className="card blogs-card h-100">
                  <div className="Img-blog">
                    <img src="/images/04.png" alt="" />
                  </div>
                  <div className="bottom-card px-3 pb-3">
                    <div className="marketing-badge-top">
                      <badge className="marketing-badge">MARKETING</badge>
                    </div>
                    <div
                      className="d-flex align-items-center py-3"
                      style={{ fontSize: 14, color: "#007BFC" }}
                    >
                      {/* <i class="fa-solid fa-calendar-days"></i> */}
                      <p className="ps-2">February 17, 2020</p>
                    </div>
                    <h3>CCPA Compliance Update</h3>
                    <p className="pb-3 pt-1">
                      It is a long established fact that a reader will be
                      distracted by the readable.
                    </p>
                    <p
                      className="d-flex align-items-center"
                      style={{ color: "#007bfc", gap: 15 }}
                    >
                      {" "}
                      Read More <i className="fa-solid fa-angle-right" />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mt-5">
                <div className="card blogs-card h-100">
                  <div className="Img-blog">
                    <img src="/images/04.png" alt="" />
                  </div>
                  <div className="bottom-card px-3 pb-3">
                    <div className="marketing-badge-top">
                      <badge className="marketing-badge">MARKETING</badge>
                    </div>
                    <div
                      className="d-flex align-items-center py-3"
                      style={{ fontSize: 14, color: "#007BFC" }}
                    >
                      {/* <i class="fa-solid fa-calendar-days"></i> */}
                      <p className="ps-2">February 17, 2020</p>
                    </div>
                    <h3>Introducing Object Storage!</h3>
                    <p className="pb-3 pt-1">
                      It is a long established fact that a reader will be
                      distracted by the readable.
                    </p>
                    <p
                      className="d-flex align-items-center"
                      style={{ color: "#007bfc", gap: 15 }}
                    >
                      {" "}
                      Read More <i className="fa-solid fa-angle-right" />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 mt-5">
                <div className="card blogs-card h-100">
                  <div className="Img-blog">
                    <img src="/images/04.png" alt="" />
                  </div>
                  <div className="bottom-card px-3 pb-3">
                    <div className="marketing-badge-top">
                      <badge className="marketing-badge">MARKETING</badge>
                    </div>
                    <div
                      className="d-flex align-items-center py-3"
                      style={{ fontSize: 14, color: "#007BFC" }}
                    >
                      {/* <i class="fa-solid fa-calendar-days"></i> */}
                      <p className="ps-2">February 17, 2020</p>
                    </div>
                    <h3>Content Marketing</h3>
                    <p className="pb-3 pt-1">
                      It is a long established fact that a reader will be
                      distracted by the readable.
                    </p>
                    <p
                      className="d-flex align-items-center"
                      style={{ color: "#007bfc", gap: 15 }}
                    >
                      {" "}
                      Read More <i className="fa-solid fa-angle-right" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer-portion */}
      </div>
    </div>
  )
}

export default Blog