import React from 'react'
import RecentBlog from './RecentBlog'
import CarousalClients from '../Components/CarousalClients'

function Clients() {
  return (
    <div>
       <div>
      <div style={{ backgroundColor: "#0d1e67", minHeight: 400 }}>
        <div className="container" style={{ maxWidth: 1170 }}>
          {/* Navbar */}
          {/* Breadcrumbs */}
          <div className="pt-5 text-center text-white">
            <h1 className="text-white pt-5">Clients</h1>
            <div className="d-flex align-items-center justify-content-center pt-2">
              <p>
                <i className="fa-solid fa-house-chimney" />
                <span className="ps-1">Home</span>
              </p>
              <p className="px-3">
                <i className="fa-solid fa-angle-right" />
              </p>
              <p>Clients</p>
            </div>
          </div>
        </div>
      </div>
      {/* Team */}
      <div className="text-center sections-margin">
        <div className="container" style={{ maxWidth: 1170 }}>
          <p className="badge badge-primary">Our Clients</p>
          <h1 className="pt-3 pb-2">All Our Great Clients</h1>
          {/* Carousel */}
          <CarousalClients />
          {/* <div className="owl-carousel owl-theme text-start pt-4 first-carousel">
            <div className="item">
              <div className="clients-img">
                <img src="/images/12.png" alt="" className="" />
              </div>
            </div>
            <div className="item">
              <div className="clients-img">
                <img src="/images/13.png" alt="" className="" />
              </div>
            </div>
            <div className="item">
              <div className="clients-img">
                <img src="/images/14.png" alt="" className="" />
              </div>
            </div>
            <div className="item">
              <div className="clients-img">
                <img src="/images/15.png" alt="" className="" />
              </div>
            </div>
            <div className="item">
              <div className="clients-img">
                <img src="/images/16.png" alt="" className="" />
              </div>
            </div>
            <div className="item">
              <div className="clients-img">
                <img src="/images/17.png" alt="" className="" />
              </div>
            </div>
          </div> */}
          {/* Carousel 2*/}
          {/* <div className="owl-carousel owl-theme text-start pt-4 first-carousel">
            <div className="item">
              <div className="clients-img">
                <img src="/images/13.png" alt="" className="" />
              </div>
            </div>
            <div className="item">
              <div className="clients-img">
                <img src="/images/15.png" alt="" className="" />
              </div>
            </div>
            <div className="item">
              <div className="clients-img">
                <img src="/images/17.png" alt="" className="" />
              </div>
            </div>
            <div className="item">
              <div className="clients-img">
                <img src="/images/12.png" alt="" className="" />
              </div>
            </div>
            <div className="item">
              <div className="clients-img">
                <img src="/images/14.png" alt="" className="" />
              </div>
            </div>
            <div className="item">
              <div className="clients-img">
                <img src="/images/16.png" alt="" className="" />
              </div>
            </div>
          </div> */}
          <CarousalClients />
          <CarousalClients />
          {/* Carousel 3*/}
          {/* <div className="owl-carousel owl-theme text-start pt-4 first-carousel">
            <div className="item">
              <div className="clients-img">
                <img src="/images/16.png" alt="" className="" />
              </div>
            </div>
            <div className="item">
              <div className="clients-img">
                <img src="/images/14.png" alt="" className="" />
              </div>
            </div>
            <div className="item">
              <div className="clients-img">
                <img src="/images/12.png" alt="" className="" />
              </div>
            </div>
            <div className="item">
              <div className="clients-img">
                <img src="/images/17.png" alt="" className="" />
              </div>
            </div>
            <div className="item">
              <div className="clients-img">
                <img src="/images/15.png" alt="" className="" />
              </div>
            </div>
            <div className="item">
              <div className="clients-img">
                <img src="/images/13.png" alt="" className="" />
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/* Language */}
      <div className="sections-margin text-start bg-img-language py-5">
        <div className="container py-5" style={{ maxWidth: 1170 }}>
          <div className="row text-white">
            <div className="col-lg-6 mb-lg-0 mb-5">
              <p className="badge badge-primary">Language</p>
              <h1 className="py-4 text-white">
                Work with the language you already love
              </h1>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution.
              </p>
              <div className="row py-4">
                <div className="col-lg-6">
                  <div
                    className="d-flex align-items-center justify-content-start"
                    style={{ gap: 10 }}
                  >
                    <i
                      className="fa-solid fa-check"
                      style={{ color: "#007BFC" }}
                    />
                    <p> Public Cloud </p>
                  </div>
                  <div
                    className="d-flex align-items-center py-2 justify-content-start"
                    style={{ gap: 10 }}
                  >
                    <i
                      className="fa-solid fa-check"
                      style={{ color: "#007BFC" }}
                    />
                    <p> Private Cloud </p>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-start"
                    style={{ gap: 10 }}
                  >
                    <i
                      className="fa-solid fa-check"
                      style={{ color: "#007BFC" }}
                    />
                    <p> Virtual Private Cloud </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className="d-flex align-items-center justify-content-start"
                    style={{ gap: 10 }}
                  >
                    <i
                      className="fa-solid fa-check"
                      style={{ color: "#007BFC" }}
                    />
                    <p> Tally-as-a-Service </p>
                  </div>
                  <div
                    className="d-flex align-items-center py-2 justify-content-start"
                    style={{ gap: 10 }}
                  >
                    <i
                      className="fa-solid fa-check"
                      style={{ color: "#007BFC" }}
                    />
                    <p> Banking Community Cloud </p>
                  </div>
                  <div
                    className="d-flex align-items-center justify-content-start"
                    style={{ gap: 10 }}
                  >
                    <i
                      className="fa-solid fa-check"
                      style={{ color: "#007BFC" }}
                    />
                    <p> Load Balancing </p>
                  </div>
                </div>
              </div>
              <button
                className="blue-btn mt-2"
                type="submit"
                style={{ height: "auto", padding: "9px 30px 10px 30px" }}
              >
                Read more
              </button>
            </div>
            <div className="col-lg-6">
              {/* Pop up */}
              <div className="d-flex align-items-center justify-content-center h-100">
                {/* Button trigger modal */}
                <span className="model-btn model-animation" />
                <button
                  type="button"
                  className="model-btn"
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                >
                  <i className="fa-solid fa-play" />
                </button>
                {/* Modal */}
                <div
                  className="modal fade"
                  id="exampleModalCenter"
                  tabIndex={-1}
                  role="dialog"
                  aria-labelledby="exampleModalCenterTitle"
                  aria-hidden="true"
                >
                  <div
                    className="modal-dialog modal-dialog-centered"
                    role="document"
                  >
                    <div className="modal-content">
                      <iframe
                        width={560}
                        height={315}
                        src="https://www.youtube.com/embed/XHOmBV4js_E?si=RHiQmm-mN4eFOTME"
                        title="YouTube video player"
                        frameBorder={0}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <iframe width="560" height="315"
                      src="https://www.youtube.com/embed/XHOmBV4js_E?si=RHiQmm-mN4eFOTME"
                      title="YouTube video player" frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
            </div>
          </div>
        </div>
      </div>
      {/* Blog */}
      <div className="text-center sections-margin">
        <div className="container" style={{ maxWidth: 1170 }}>
          <p className="badge badge-primary">Blog</p>
          <h1 className="pb-4 pt-3">Recent Blog</h1>
          {/* Carousel */}
          <RecentBlog />
          {/* <div className="owl-carousel owl-theme text-start second-carousel">
            <div className="item">
              <div className="card blogs-card">
                <div className="Img-blog">
                  <img src="/images/04.png" alt="" />
                </div>
                <div className="bottom-card px-3 pb-3">
                  <div className="marketing-badge-top">
                    <badge className="marketing-badge">MARKETING</badge>
                  </div>
                  <div
                    className="d-flex align-items-center py-3"
                    style={{ fontSize: 14, color: "#007BFC" }}
                  >
                    <i className="fa-solid fa-calendar-days" />
                    <p className="ps-2">February 17, 2020</p>
                  </div>
                  <h5>Official Terraform Provider</h5>
                  <p className="pb-3 pt-1">
                    It is a long established fact that a reader will be
                    distracted by the readable.
                  </p>
                  <p
                    className="d-flex align-items-center"
                    style={{ color: "#007bfc", gap: 15 }}
                  >
                    {" "}
                    Read More <i className="fa-solid fa-angle-right" />
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="card blogs-card">
                <div className="Img-blog">
                  <img src="/images/04.png" alt="" />
                </div>
                <div className="bottom-card px-3 pb-3">
                  <div className="marketing-badge-top">
                    <badge className="marketing-badge">MARKETING</badge>
                  </div>
                  <div
                    className="d-flex align-items-center py-3"
                    style={{ fontSize: 14, color: "#007BFC" }}
                  >
                    <i className="fa-solid fa-calendar-days" />
                    <p className="ps-2">February 17, 2020</p>
                  </div>
                  <h5>CCPA Compliance Update</h5>
                  <p className="pb-3 pt-1">
                    It is a long established fact that a reader will be
                    distracted by the readable.
                  </p>
                  <p
                    className="d-flex align-items-center"
                    style={{ color: "#007bfc", gap: 15 }}
                  >
                    {" "}
                    Read More <i className="fa-solid fa-angle-right" />
                  </p>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="card blogs-card">
                <div className="Img-blog">
                  <img src="/images/04.png" alt="" />
                </div>
                <div className="bottom-card px-3 pb-3">
                  <div className="marketing-badge-top">
                    <badge className="marketing-badge">MARKETING</badge>
                  </div>
                  <div
                    className="d-flex align-items-center py-3"
                    style={{ fontSize: 14, color: "#007BFC" }}
                  >
                    <i className="fa-solid fa-calendar-days" />
                    <p className="ps-2">February 17, 2020</p>
                  </div>
                  <h5>Build Construction</h5>
                  <p className="pb-3 pt-1">
                    It is a long established fact that a reader will be
                    distracted by the readable.
                  </p>
                  <p
                    className="d-flex align-items-center"
                    style={{ color: "#007bfc", gap: 15 }}
                  >
                    {" "}
                    Read More <i className="fa-solid fa-angle-right" />
                  </p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      {/* Footer-portion */}
    </div>
    </div>
  )
}

export default Clients